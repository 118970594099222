import { Avatar, Button, Popconfirm, message } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import { useRecoilState } from 'recoil';

import { FiUserPlus } from 'react-icons/fi';
import { RiCloseCircleFill } from 'react-icons/ri';
import ENDPOINTS from 'app/Services/endpoints';
import Api from 'app/Services';
import { IUserTableData, TAny } from "app/typings";
import Scaffold from 'app/__portions/Scaffold';
import SideMenus from 'app/__portions/SideMenus';
import { UsersState } from 'app/bloc/atoms';
import { toUser } from 'app/utils/helpers';
import UsersTable from './UsersTable';
import CreateUser from './CreateUser';

const UsersActivity = () => {
  const [
    { currentUser, hasLoaded, loading, showDetails, users },
    setUser,
  ] = useRecoilState(UsersState);

  const [deleting, setDeleting] = useState(false);

  const onRowClick = (currentUser:TAny) => {
    setUser((currVal) => ({
      ...currVal,
      currentUser,
      showDetails: true,
    }));
  };

  const onEdit = (currentUser = {}) => {
    if (currentUser && Object.keys(currentUser).length) {
      setUser((currVal) => ({
        ...currVal,
        isEdit: true,
        isCreate: false,
        showDetails: false,
        currentUser,
      }));
      return;
    }
    
    setUser((currVal) => ({
      ...currVal,
      isEdit: true,
      isCreate: false,
      showDetails: true,
    }));
  };

  const onDelete = async (record?: IUserTableData) => {
    const user = record || currentUser;
    setDeleting(true);

    const { data, error } = await Api.delete(
      `${ENDPOINTS.USERS}/${user.key}`,
    );

    if (error) {
      message.error('Failed to delete the user. Please try again');
      setDeleting(false);
      return;
    }

    if (data) {
      const allUsers = users.filter(
        (userData) => userData.key !== user.key,
      );

      const fullName = `${
        currentUser?.info?.firstName || record?.info?.firstName
      } ${currentUser.info?.lastName || record?.info?.lastName}`;

      message.success(
        `${fullName || 'User'} was deleted successfully`,
      );
      setDeleting(false);
      setUser((currVal) => ({
        ...currVal,
        currentUser: {},
        users: allUsers,
        showDetails: false,
      }));
    }
  };

  const fetchUsers = useCallback(async () => {
    setUser((state) => ({ ...state, loading: true }));
    const { data, error } = await Api.get(ENDPOINTS.USERS);

    if (error) {
      setUser((state) => ({
        ...state,
        loading: false,
        hasLoaded: false,
      }));
      return;
    }

    const dataUsers = data.map(toUser);

    setUser((state) => ({
      ...state,
      loading: false,
      hasLoaded: true,
      users: dataUsers,
    }));
  }, [setUser]);

  useEffect(() => {
    if (!hasLoaded) {
      fetchUsers();
    }
  }, [fetchUsers, hasLoaded]);

  return (
    <Scaffold hideSideBarInMedium={false} sideBar={<SideMenus />}>
      <div className="ant-row ant-row-space-between ant-space-align-center mb-2">
        <h1 className="text-lg letter-28 text-black-50">USERS</h1>

        <Button
          type="primary"
          className="items-center rounded-md h-full btn-height px-3"
          style={{ display: 'flex' }}
          onClick={() =>
            setUser((currval) => ({
              ...currval,
              isCreate: true,
              isEdit: false,
            }))
          }
        >
          <FiUserPlus size="16px" className="mr-3" />
          Add User
        </Button>
      </div>

      <div className="bg-white rounded-md p-4 flex flex-nowrap">
        <div className="flex-1 animate-all-500" style={{ paddingRight: '16px' }}>
          <UsersTable
            data={users}
            loading={loading}
            performing={deleting}
            onRowClick={onRowClick}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        </div>
        {(showDetails && (
          <div
            className="border-l flex-1 hidden xlg-block animate-all-500"
            style={{ width: '20%' }}
          >
            <div className="p-4" role="button">
              <RiCloseCircleFill
                size={24}
                className="text-black-50"
                onClick={() =>
                  setUser((state) => ({
                    ...state,
                    showDetails: false,
                  }))
                }
              />
            </div>
            <div className="my-5 text-center">
              <Avatar
                src={currentUser.info?.avatarUrl}
                size={150}
                className="border rounded-sm"
                shape="square"
              />
              <div className="mt-3">
                {currentUser.info?.firstName}{' '}
                {currentUser.info?.lastName}
              </div>
              <div className="text-black-50">{currentUser?.role}</div>
            </div>

            <div className="text-left p-4 border-t my-5">
              <div className="font-bold text-black-50">EMAIL</div>
              <div className="mb-3">{currentUser.email}</div>
            </div>

            <div className="mt-3 flex ant-ant-row-space-around p-4">
              <Button
                disabled={deleting}
                onClick={() => onEdit()}
                className="border-w-2 rounded-md w-full btn-height px-3 mr-2"
              >
                Edit
              </Button>
              <Popconfirm
                title={`Are you sure to delete this ${currentUser.info?.firstName}?`}
                onConfirm={() => onDelete()}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  danger
                  className="border-w-2 rounded-md w-full btn-height px-3 ml-2"
                  disabled={deleting}
                  loading={deleting}
                >
                  Delete
                </Button>
              </Popconfirm>
            </div>
          </div>
        )) || <div className="all-500" />}
      </div>
      <CreateUser />
    </Scaffold>
  );
};

export default UsersActivity;
