import { useHistory } from "react-router-dom";
import { useGotoRecoilSnapshot, useRecoilSnapshot } from "recoil";
import Api from "app/Services";
import ENDPOINTS from "app/Services/endpoints";
import LocalStorage from "app/utils/helpers/LocalStorage";
import { DEMAND_PLANNING_DEFAULT } from "app/bloc/defaults";
import { DemandPlanningState, expandedKeysState, selectedKeysState } from "app/bloc/atoms";

const useLogout = () => {
  const history = useHistory();

  const snapshot = useRecoilSnapshot(); // Subscribe to all state changes
  const gotoSnapshot = useGotoRecoilSnapshot();

  const resetAtoms = snapshot.map((snap) => {
    snap.set(DemandPlanningState, DEMAND_PLANNING_DEFAULT);
    snap.set(selectedKeysState, (val) => ({
      ...val,
      planningKeys: [],
    }));
    snap.set(expandedKeysState, (val) => ({
      ...val,
      planningKeys: [],
    }));

    return snap;
  });

  return async () => {
    await Api.post(ENDPOINTS.LOG_OUT, {
      refresh_token: LocalStorage.getRefreshToken(),
    });

    LocalStorage.logOut();

    const release = resetAtoms.retain();
    try {
      gotoSnapshot(resetAtoms);
    } finally {
      release();
    }

    history.replace('/');
  };
};

export default useLogout;
