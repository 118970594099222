import { USER_ROLES } from '../helpers/constants';
import LocalStorage from '../helpers/LocalStorage';
import keys from './keys';

export const currentRole = () => {
  const roleKey = LocalStorage.get(keys.USER_ROLE_STORAGE);
  const userRole = Object.values(USER_ROLES).find(
    ({ value }) => value === roleKey,
  );

  return userRole;
};

export const isRole = (role = USER_ROLES.Sales.value) => {
  return currentRole()?.value === role;
};
