import JsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { IResponseAccuracyData, IResponseWaterfallData, TAny } from "../../typings";

const PdfReportGenerator = (
  horizon: string[],
  from: string,
  to: string,
  lag: number,
  item: string,
  data : IResponseAccuracyData | IResponseWaterfallData | undefined
) => {
  try {
    const pdf = new JsPDF({ orientation: "portrait" })

    pdf.setProperties({
      title: `FORECAST VALUE ADDED FROM ${from} TO ${to}`
    })

    const imageUrl = "/public/favicons/apple-icon.png";

    pdf.addImage(imageUrl, 'JPEG', 180, 8, 20, 20);
    pdf.setFontSize(10);
    pdf.setFont('helvetica', 'bold');
    pdf.setFontSize(24);
    pdf.text('BARBADOS', 10, 16);

    pdf.setLineWidth(0.1);
    pdf.setDrawColor(200, 200, 200);
    pdf.setFontSize(10);

    pdf.setFont('helvetica', 'bold')
    pdf.text('Forecast Type :', 10, 30)
    pdf.text('From Date        :', 10, 34)
    pdf.text('To Date            :', 10, 38)

    pdf.setFont('helvetica', 'normal')

    pdf.text("ACCURACY EVOLUTION", 40, 30)
    pdf.text(from, 40, 34)
    pdf.text(to, 40, 38)

    pdf.line(10, 42, 200, 42)

    pdf.setFont('helvetica', 'bold')
    pdf.text('Lag selection  :', 10, 48)
    pdf.setFont('helvetica', 'normal')
    pdf.text(`${lag}`, 40, 48)

    pdf.setFont('helvetica', 'bold')
    pdf.text('Product            :', 10, 54)
    pdf.setFont('helvetica', 'normal')
    pdf.text(`${item}`, 40, 54)

    pdf.text(`All items below correspond to the forecast horizon from ${from} to ${to}`, 10, 64)
    pdf.setFont('helvetica', 'normal')

    pdf.setFillColor(41,68,45,5);
    pdf.rect(100, 70, 100, 6, "F");

    pdf.setDrawColor('orange')
    pdf.setTextColor('white')
    pdf.text('METRICS', 110, 74)

    const compiledResult= horizon!.reduce((acc, date) => {
      acc[date] = {
        date,
        actual  : data!.actual  [date] || "-",
        forecast: data!.forecast[date] || "-",
        metrics: {
          MAE  : data!.metrics.MAE  [date] || "-",
          BIAS : data!.metrics.BIAS [date] || "-",
          RMSE : data!.metrics.RMSE [date] || "-",
          MAPE : data!.metrics.MAPE [date] || "-",
          wMAPE: data!.metrics.wMAPE[date] || "-"
        },
        leads: data!.leads[date] || "-"
      };

      return acc;
    }, {});

    const itemDetailsRows = Object.values(compiledResult).map((item: TAny) => [
      item.date    ?.toString(),
      item.actual  ?.toString(),
      item.forecast?.toString(),
      item.leads   ?.toString(),
      item.metrics?.MAE  ?.toString(),
      item.metrics?.BIAS ?.toString(),
      item.metrics?.RMSE ?.toString(),
      item.metrics?.MAPE ?.toString(),
      item.metrics?.wMAPE?.toString(),
    ]);

    const itemDetailsHeaders = [
      'Date',
      'Actual',
      'Forecast',
      'Lead',
      'MAE',
      'BIAS',
      'RMSE',
      'MAPE',
      'wMAPE'
    ];

    const columnWidths = [30, 20, 20, 20, 20, 20, 20, 20, 20];

    pdf.setFont('helvetica');

    autoTable(pdf,{
      head: [itemDetailsHeaders],
      body: itemDetailsRows,
      startY: 76,
      headStyles: {
        fontSize : 10,
        font     : 'helvetica',
        halign   : 'left',
        fillColor: 'orange',
        textColor: 'black'
      },
      columnStyles: {
        0: { cellWidth: columnWidths[0] },
        1: { cellWidth: columnWidths[1] },
        2: { cellWidth: columnWidths[2] },
        3: { cellWidth: columnWidths[3] },
        4: { cellWidth: columnWidths[4] },
        5: { cellWidth: columnWidths[5] },
        6: { cellWidth: columnWidths[6] },
        7: { cellWidth: columnWidths[7] },
        8: { cellWidth: columnWidths[8] },
      },
      alternateRowStyles: { fillColor: [255, 255, 255] },
      bodyStyles: {
        fontSize: 10,
        font: 'helvetica',
        cellPadding: { top: 1, right: 5, bottom: 1, left: 2 },
        textColor: [0, 0, 0],
      },
      margin: { top: 10, left: 10 },
    });

    // @ts-ignore
    const totalPages = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i+=1) {
      pdf.line(10, 283, 200, 283)
      pdf.setPage(i);
      pdf.setFont('helvetica');
      pdf.text(
        `Page ${i} of ${totalPages}`,
        185,
        pdf.internal.pageSize.getHeight() - 5
      );
    }

    const pdfDataUri = pdf.output('datauristring');
    const newTab = window.open();
    newTab?.document.write(`<iframe width='100%' height='100%' src='${pdfDataUri}'></iframe>`);
  } catch (error) {
    console.error(error)
  }
};

export default PdfReportGenerator