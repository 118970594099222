import React from 'react';

import { DatePicker } from 'antd';
import moment from 'moment';
import { useRecoilState } from "recoil";
import { RangeValue } from 'rc-picker/lib/interface';
import { HorizonRangeDataState } from "app/bloc/atoms";
import { getGranularity } from "../../../utils/helpers";

const { RangePicker } = DatePicker;

interface Props {
  value?: RangeValue<moment.Moment> | undefined;
  onChange?: ((values: [string, string]) => void) | null;
  onOpenChange?: (open: boolean) => void;
}

const ForecastHorizon = ({ value, onChange, onOpenChange }:Props) => {
  const [, setHorizonRange] = useRecoilState(HorizonRangeDataState);

  const onDayChange = (_values: RangeValue<moment.Moment>, formatString: [string, string]) => {
    if (onChange) {
      onChange?.(formatString);
    }
    setHorizonRange(formatString);
  };

  return (
    <div>
      <div className="text-xs mb-1 text-black">
        Forecast Horizon
      </div>
      <RangePicker
        picker={getGranularity()}
        value={value}
        // @ts-ignore
        onChange={onDayChange}
        onOpenChange={onOpenChange}
        className="rounded-md border-w-2 btn-height"
      />
    </div>
  );
};

ForecastHorizon.defaultProps = {
  value: undefined,
  onChange: null,
  onOpenChange: undefined,
};

export default ForecastHorizon;
