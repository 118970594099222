import { Layout, Result, Skeleton, Space } from "antd";
import React, { Key, useCallback, useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  FvaTableData,
  HorizonRangeDataState, HorizonRangeState, LagSelectionState,
  FvaDataState,
  selectedKeysState,
  TreeDataState
} from "app/bloc/atoms";
import { getFetchDataTreePayload, getDaysBetweenDates } from "app/utils/helpers";
import ForecastHorizon from "app/__portions/Selectors/ForecastHorizon";
import { IResponseFvaData, RowType, TAny } from "app/typings";
import Scaffold from "app/__portions/Scaffold";
import LocalStorage from "../../utils/helpers/LocalStorage";
import ForecastReportFva from "./ForecastReportFva";
import LagSelection from "../../__portions/Selectors/LagSelection";
import Api from "../../Services";
import ENDPOINTS from "../../Services/endpoints";

const ForecastValueAdded = () => {
  const printRef = useRef<HTMLDivElement>(null)
  const [fvaData, setFvaData] = useRecoilState(FvaTableData);

  const treeDataState  = useRecoilValue(TreeDataState);
  const horizonRange   = useRecoilValue(HorizonRangeDataState);
  const [, setHorizon] = useRecoilState(HorizonRangeState);
  const [{ loading, hasLoaded }, setFva] = useRecoilState(FvaDataState);

  const [{ reportKeys }, setSelectedKeys] = useRecoilState(selectedKeysState);

  const lagSelection = useRecoilValue(LagSelectionState);

  const [lag, setLag] = useState('');
  const [responseBody, setResponseBody] = useState<IResponseFvaData>();

  const fetchData = useCallback(async (lines:Record<string, TAny>[] = treeDataState.treeDataResponse) => {

        const payload = {
          lines,
          start_date: `${horizonRange[0]}`,
          end_date  : `${horizonRange[1]}`,
          lag_number: lagSelection,
        };

        setFva((prev) => {
          return { ...prev, loading: true };
        });

        const { data: responseData } = await Api.post(
          ENDPOINTS.FETCH_FVA,
          payload
        );

      // const { data: responseData } = fvaJson

        if (responseData) {
          const columnsRange = getDaysBetweenDates(
              moment(horizonRange[0], 'YYYY-MM-DD'),
              moment(horizonRange[1], 'YYYY-MM-DD'),
          );

          const {
            actual,
            statistical_forecast,
            planner_forecast,
            sales_rep_forecast,
            statistical_mape,
            planner_mape,
            sales_rep_mape,
            fva_sales_rep_mape,
            fva_planner_mape
          } = responseData;

          setLag(columnsRange[0])
          setFva((prev) => {
            return {
              ...prev,
              responseData
            }
          })

          setResponseBody(responseData)
          setHorizon(columnsRange)

          const actualRow = {
            key: 'actual highlight',
            reportTitle: 'Actual',
            ...actual,
          };

          const statForecastRow = {
            key: 'statistical_forecast',
            reportTitle: 'Stat',
            ...statistical_forecast,
          };

          const plannerForecastRow = {
            key: 'planner_forecast',
            reportTitle: 'Planner',
            ...planner_forecast,
          };

          const salesRepForecastRow = {
            key: 'sales_rep_forecast',
            reportTitle: 'Sales',
            ...sales_rep_forecast,
          };

          const statMapeRow = {
            key: 'statistical_mape',
            reportTitle: 'Stat MAPE',
            ...statistical_mape,
          };

          const plannerMapeRow = {
            key: 'planner_mape',
            reportTitle: 'Planner MAPE',
            ...planner_mape,
          };

          const salesRepMapeRow = {
            key: 'sales_rep_mape',
            reportTitle: 'Sales MAPE',
            ...sales_rep_mape,
          };

          const salesRepFvaRow = {
            key: 'fva_sales_rep_mape',
            reportTitle: 'Fva Planner',
              ...fva_sales_rep_mape,
          };

          const plannerFvaRow = {
            key: 'fva_planner_mape',
            reportTitle: 'Fva Sales',
            ...fva_planner_mape,
          };

          setFvaData((prevData) => ({
            ...prevData,
            actualRow ,
            statForecastRow,
            plannerForecastRow,
            salesRepForecastRow,
            statMapeRow,
            plannerMapeRow,
            salesRepMapeRow,
            salesRepFvaRow,
            plannerFvaRow,
            dataLoaded: true,
          }));
        }
      },
      [
        treeDataState.treeDataResponse,
        horizonRange,
        lagSelection,
      ],
  );

  useEffect(() => {
    if (!hasLoaded && horizonRange.length && treeDataState.treeDataResponse.length) {
      if(LocalStorage.getTreeSelection() !== null && LocalStorage.getSelectedTreeKey() !== null){
        // @ts-ignore
        fetchData(JSON.parse(LocalStorage.getTreeSelection()))
      } else {
        fetchData()
      }
    }
  }, [
    horizonRange.length,
    hasLoaded,
    fetchData,
    treeDataState.treeDataResponse,
  ]);

  const onSelect = (keys: Key[], info: TAny) => {
    setSelectedKeys((prev) => ({ ...prev, reportKeys: keys }))

    const payload = getFetchDataTreePayload(
        info.node,
        treeDataState.treeDataResponse
    )

    LocalStorage.setTreeSelection(JSON.stringify(payload))
    LocalStorage.setSelectedTreeKey(JSON.stringify(keys))

    fetchData(payload)
  }

  useEffect(() => {
    if (fvaData.dataLoaded) {
      const data = [] as RowType[];
      // if (fvaData.actualRow.length) {
        data.push(fvaData.actualRow);
      // }

      // if (fvaData.statForecastRow.length) {
        data.push(fvaData.statForecastRow);
      // }

      // if (fvaData.plannerForecastRow.length) {
        data.push(fvaData.plannerForecastRow);
      // }

      // if (fvaData.salesRepForecastRow.length) {
        data.push(fvaData.salesRepForecastRow);
      // }

      // if (fvaData.statMapeRow.length) {
        data.push(fvaData.statMapeRow);
      // }

      // if (fvaData.plannerMapeRow.length) {
        data.push(fvaData.plannerMapeRow);
      // }

      // if (fvaData.salesRepMapeRow.length) {
        data.push(fvaData.salesRepMapeRow);
      // }

      // if (fvaData.salesRepFvaRow.length) {
        data.push(fvaData.salesRepFvaRow);
      // }

      // if (fvaData.plannerFvaRow.length) {
        data.push(fvaData.plannerFvaRow);
      // }

      setFva((preValue) => {
        return {
          ...preValue,
          data,
          historyLoaded: true,
          loading      : false,
          hasLoaded    : true,
        };
      });
    }
  }, [fvaData,  setFva, lag, lagSelection]);


  const handleLagSelection = () => {
    if (horizonRange.length && treeDataState.treeDataResponse.length) {
      if(LocalStorage.getTreeSelection() !== null && LocalStorage.getSelectedTreeKey() !== null){
        // @ts-ignore
        fetchData(JSON.parse(LocalStorage.getTreeSelection()))
      } else {
        fetchData()
      }
    }
  }

  const onOpenChange = (open: boolean) => {
    if (horizonRange.length && treeDataState.treeDataResponse.length) {
      if (!open) {
        if(LocalStorage.getTreeSelection() !== null && LocalStorage.getSelectedTreeKey() !== null){
          // @ts-ignore
          fetchData(JSON.parse(LocalStorage.getTreeSelection()))
        } else {
          fetchData()
        }
      }
    }
  }

  return (
      <Scaffold selectedKeys={reportKeys} onSelect={onSelect}>
        {(hasLoaded && treeDataState.treeData.length === 0) && (
            <Layout>
              <div className="p-2 md:p-4 pb-16 overflow-y-auto w-full">
                <Result
                    status="404"
                    title='Empty Data'
                    subTitle="Sorry, it seems there is no data found. Please try again later..."
                    extra={
                      <Link to="/" type="primary">
                        Refresh Page
                      </Link>
                    }
                />
              </div>
            </Layout>
        ) || (
          <div ref={printRef}>
            <Layout>
              {loading && (
                  <Layout>
                    <Layout style={{
                      display: 'flex',
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between"
                    }}>
                      <Skeleton.Input style={{width: 175, borderRadius: 8}} active size="small"/>
                      <div style={{display: 'flex', gap: 2}}>
                        <div style={{display: 'flex', flexDirection: "column", gap: 2}}>
                          <Skeleton.Input style={{width: 100, borderRadius: 8}} active size="small"/>
                          <Skeleton.Input style={{width: 50, borderRadius: 8}} active size="large"/>
                        </div>
                        <div style={{display: 'flex', flexDirection: "column", gap: 2}}>
                          <Skeleton.Input style={{width: 100, borderRadius: 8}} active size="small"/>
                          <Skeleton.Input style={{width: 230, borderRadius: 8}} active size="large"/>
                        </div>
                      </div>
                    </Layout>
                  </Layout>
              ) || (
                    <Layout style={{
                      display: 'flex',
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between"
                    }}>
                      <h1 className="text-lg font-bold letter-28 text-black">
                        FORECAST VALUE ADDED
                      </h1>
                      <Space size="large">
                        <LagSelection onChange={handleLagSelection} />
                        <ForecastHorizon
                          value={[ moment(horizonRange[0]), moment(horizonRange[1])]}
                          onOpenChange={onOpenChange}
                        />
                      </Space>
                    </Layout>
              )}
              <ForecastReportFva data={responseBody}/>
            </Layout>
          </div>
        )}
      </Scaffold>
  );
};

export default ForecastValueAdded;
