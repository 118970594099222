import React, { useEffect, useMemo, useState } from 'react';
import {
  AutoComplete,
  Avatar,
  Button,
  Popconfirm,
  Space,
  Table,
} from 'antd';
import { HiOutlineSearch } from 'react-icons/hi';
import { IUserTableData } from 'app/typings';

type Props = {
  data: IUserTableData[];
  loading: boolean;
  performing?: boolean;
  onRowClick?: (record: IUserTableData) => void;
  onEdit?: (record: IUserTableData) => void;
  onDelete?: (record: IUserTableData) => void;
};

const UsersTable = ({
  data,
  loading,
  performing,
  onRowClick,
  onEdit,
  onDelete,
}: Props) => {
  const [dataSource, setDataSource] = useState(data);

  const columns = useMemo(
    () => [
      {
        title: 'NAME',
        dataIndex: 'info',
        onHeaderCell: () => ({ className: 'bg-transparent' }),
        render: (details, record) => {
          if (details && Object.keys(details).length) {
            return (
              <div
                role="button"
                tabIndex={0}
                onKeyDown={() => null}
                className="ant-row ant-row-no-wrap ant-space-align-center"
                onClick={() => onRowClick?.(record)}
              >
                <Avatar
                  src={details.avatarUrl}
                  size="large"
                  className="flex-shrink-0 border"
                />
                <div className="flex-1 ml-2">
                  {details.firstName || details.lastName
                    ? `${details.firstName} ${details.lastName}`
                    : 'Member'}
                </div>
              </div>
            );
          }
          return details;
        },
      },
      {
        title: 'ROLE',
        dataIndex: 'role',
        onHeaderCell: () => ({ className: 'bg-transparent' }),
      },
      {
        title: 'EMAIL',
        dataIndex: 'email',
        onHeaderCell: () => ({ className: 'bg-transparent' }),
      },
      {
        dataIndex: 'actions',
        width: 200,
        onHeaderCell: () => ({ className: 'bg-transparent' }),
        render: (_, record) => {
          return (
            <Space>
              <Button
                className="rounded-sm"
                disabled={performing}
                onClick={() => onEdit?.(record)}
              >
                Edit
              </Button>
              <Popconfirm
                title={`Are you sure to delete this ${
                  `${record.info?.firstName} ${record.info?.lastName}` ||
                  'user'
                }?`}
                onConfirm={() => onDelete?.(record)}
                okText="Yes"
                cancelText="No"
                disabled={performing}
              >
                <Button
                  className="rounded-sm"
                  danger
                  disabled={performing}
                >
                  Delete
                </Button>
              </Popconfirm>
            </Space>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [performing, dataSource],
  );

  const options = useMemo(() => {
    return dataSource
      .filter((datum) => datum.info.firstName && datum.info.lastName)
      .map((source) => ({
        value: `${source.info.firstName} ${source.info.lastName}`,
        label: `${source.info.firstName} ${source.info.lastName}`,
      }));
  }, [dataSource]);

  const onSearch = (searchText) => {
    if (searchText) {
      const filtered = data.filter((datum) => {
        const fullName = `${datum.info.firstName} ${datum.info.lastName}`;

        return fullName
          .toLocaleLowerCase()
          .includes(searchText.toLocaleLowerCase());
      });

      setDataSource(filtered);
      return;
    }
    setDataSource(data);
  };

  useEffect(() => {
    setDataSource(data);
  }, [data]);

  return (
    <>
      <div className="mb-3 w-full">
        <AutoComplete
          allowClear
          options={options}
          placeholder={
            <div className="flex justify-center items-center absolute">
              <HiOutlineSearch size="19.2px" />
              <span className="ml-2">
                Search for a user by first name or last name
              </span>
            </div>
          }
          className="btn-height w-full autocomplete-input rounded-sm"
          onSearch={onSearch}
          onSelect={onSearch}
        />
      </div>
      <Table
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        className="bg-white"
        scroll={{ x: '120px' }}
      />
    </>
  );
};

UsersTable.defaultProps = {
  performing: false,
  onRowClick: () => null,
  onEdit: () => null,
  onDelete: () => null,
};

export default UsersTable;
