import React, { useEffect } from 'react';
import { FiUsers } from 'react-icons/fi';
import {
  AiOutlinePieChart,
  AiOutlineMenuFold,
  AiOutlineMenuUnfold,
} from 'react-icons/ai';
import { Link, useHistory } from 'react-router-dom';
import SubMenu from 'antd/lib/menu/SubMenu';
import { Menu } from 'antd';
import { useRecoilState } from 'recoil';
import useTreeData from 'app/Hooks/useTreeData';
import { CollapseSider } from 'app/bloc/atoms';
import useMediaQuery from 'app/Hooks/useMediaQuery';
import paths from 'app/routes/paths';

const SideMenus = () => {
  const history = useHistory();

  const [collapsed, setCollapsed] = useRecoilState(CollapseSider);

  const [isMediumLarge] = useMediaQuery();

  const [regions, fetchTreeData] = useTreeData();

  const onItemClick = (path) => {
    history.push(path);
  };

  useEffect(() => {
    fetchTreeData();
  }, [fetchTreeData]);

  useEffect(() => {
    if (!isMediumLarge) {
      setCollapsed(false);
    }
  }, [isMediumLarge, setCollapsed]);

  return (
    <div className="w-full my-5 flex menu-wrapper"
      style={{ flexDirection: 'column', justifyContent: 'space-between' }}
    >
      <Menu mode="inline" defaultOpenKeys={['report']} className="w-full flex-1">
        <Menu.Item key="users" icon={<FiUsers size="16px" />}
          className={` text-black hover:text-blue p-3 ${
            history.location.pathname === paths.USERS_ACTIVITY
              ? 'border-l-4 border-blue bg-primary text-blue'
              : ''
          }`}
          onClick={() => onItemClick(paths.USERS_ACTIVITY)}
        >
          <div className="px-1">Users</div>
        </Menu.Item>

        <SubMenu
          key="report"
          icon={<AiOutlinePieChart size="19.2px" className="mr-3" />}
          title="Budget Analytics"
        >
          <Menu.ItemGroup key="regions">
            {regions.map((region) => (
              <Menu.Item key={region?.key} className="hover:bg-primary">
                <Link to={`${paths.REPORT_ANALYTICS}/${region.name}`}>
                  {region?.name}
                </Link>
              </Menu.Item>
            ))}
          </Menu.ItemGroup>
        </SubMenu>
      </Menu>

      <div
        role="button"
        tabIndex={0}
        onKeyDown={() => null}
        onClick={() => setCollapsed(!collapsed)}
        className="block xlg-hidden text-center bg-gray p-1"
      >
        {collapsed ? <AiOutlineMenuUnfold /> : <AiOutlineMenuFold />}
      </div>
    </div>
  );
};

export default SideMenus;
