import React, { useEffect, useRef, useState } from 'react';

type Props = {
  tabs: string[];
  defaultIndex?: number;
  onTabChange?: (index: number) => void;
};

const Tabs = ({ tabs, defaultIndex, onTabChange }: Props) => {
  const [boundRect, setBoundRect] = useState<DOMRect>();
  const [currentIndex, setCurrentIndex] = useState(defaultIndex || 0);

  const tabItemsRef = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    if (tabItemsRef.current) {
      const item = tabItemsRef.current[currentIndex];
      const boundRect = item.getBoundingClientRect();

      setBoundRect(boundRect);
    }
  }, [currentIndex, tabItemsRef]);

  return (
    <div
      className="flex items-center bg-secondary rounded-md py-1/3 relative"
      style={{ height: 40, isolation: 'isolate' }}
    >
      <div
        className="bg-white rounded-md text-branding-blue font-medium absolute"
        style={{
          top: 0,
          bottom: 0,
          zIndex: -1,
          transition: 'all 0.15s ease-in-out 0s',
          transform: `translateX(${
            (tabItemsRef.current?.[currentIndex]?.offsetLeft || 5) - 5
          }px)`,
          width: boundRect?.width,
          margin: 4,
        }}
      />
      {tabs.map((type, index) => {
        const isCurrent = currentIndex === index;

        return (
          <div
            key={type}
            role="tab"
            aria-selected={isCurrent}
            tabIndex={0}
            onKeyDown={() => null}
            ref={(element) => {
              if (element !== null) {
                tabItemsRef.current[index] = element;
                return tabItemsRef.current[index];
              }
              return element;
            }}
            onClick={() => {
              onTabChange?.(index);
              setCurrentIndex(index);
            }}
            className={`flex items-center w-full h-full px-3 mx-1/3 text-xs font-medium ${
              isCurrent ? 'text-blue' : 'text-black-50'
            } all-15-eio`}
          >
            {type}
          </div>
        );
      })}
    </div>
  );
};

Tabs.defaultProps = {
  onTabChange: () => null,
  defaultIndex: 0,
};

export default Tabs;
