import keys from '../configs/keys';
import { TAny } from "../../typings";

class LocalStorage {
  static get(key: string) {
    return localStorage.getItem(key);
  }

  static set(key: string, value: TAny) {
    localStorage.setItem(key, value);
  }

  static remove(key: string) {
    localStorage.removeItem(key);
  }

  static setAccessToken(value: string) {
    localStorage.setItem(keys.TOKEN_STORAGE_KEY as string, value);
  }

  static getAccessToken() {
    return localStorage.getItem(keys.TOKEN_STORAGE_KEY as string);
  }

  static removeAccessToken() {
    localStorage.removeItem(keys.TOKEN_STORAGE_KEY as string);
  }

  static setRefreshToken(value: string) {
    localStorage.setItem(
      keys.REFRESH_TOKEN_STORAGE_KEY as string,
      value,
    );
  }

  static getRefreshToken() {
    return localStorage.getItem(keys.REFRESH_TOKEN_STORAGE_KEY as string);
  }

  static setTreeSelection(value: TAny) {
    localStorage.setItem(
      keys.TREE_SELECTION_KEY as string,
      value,
    );
  }

  static setSelectedTreeKey(value: TAny) {
    localStorage.setItem(
      keys.SELECTED_TREE_KEY as string,
      value,
    );
  }

  static setExpandedTreeKey(value: TAny) {
    localStorage.setItem(
      keys.EXPANDED_TREE_KEY as string,
      value,
    );
  }

  static getTreeSelection() {
    return localStorage.getItem(keys.TREE_SELECTION_KEY as string);
  }

  static getSelectedTreeKey() {
    return localStorage.getItem(keys.SELECTED_TREE_KEY as string);
  }

  static getExpandedTreeKey() {
    return localStorage.getItem(keys.EXPANDED_TREE_KEY as string);
  }

  static removeRefreshToken() {
    localStorage.removeItem(keys.REFRESH_TOKEN_STORAGE_KEY as string);
  }

  static setGranularity(value: string) {
    localStorage.setItem(keys.GRANULARITY_KEY as string, value);
  }

  static getGranularity() {
    return localStorage.getItem(keys.GRANULARITY_KEY as string) || '1';
  }

  static clear() {
    localStorage.clear();
  }

  static logOut() {
    this.remove(keys.DEFAULT_API)
    this.remove(keys.TOKEN_STORAGE_KEY)
    this.remove(keys.REFRESH_TOKEN_STORAGE_KEY)
    this.remove(keys.USER_ROLE_STORAGE)
    this.remove(keys.USER_NAME)
    this.remove(keys.DEFAULT_API)
    this.remove(keys.DEFAULT_API)
  }
}

export default LocalStorage;
