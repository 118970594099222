import React, {
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react';
import { Input, Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { TAny } from 'app/typings';

import './style.css';

const EditableContext =
  React.createContext<FormInstance<TAny> | null>(null);

interface Item {
  key: string;
  name: string;
  age: string;
  address: string;
}

export const EditableRow: React.FC = ({ ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Record<keyof Item, TAny>;
  requireMessage: string;
  suffix?: React.ReactNode;
  required?: boolean;
  loading?: boolean;
  handleSave: (record: TAny) => Promise<void>;
}

export const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  requireMessage,
  required,
  loading,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const message = ''
  const inputRef = useRef<Input>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  useEffect(() => {
    if (loading) {
      form.resetFields();
      setEditing(false);
    }
  }, [form, loading]);

  useEffect(() => {
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        setEditing(false);
      }
    });
  }, []);

  const toggleEdit = () => {
    const value = record[dataIndex]?.value || record[dataIndex];
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: value,
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      const lastAdjustedCell = Object.keys(values).slice(-1)[0];

      const adjustments = {
        [lastAdjustedCell]: {
          value: values[lastAdjustedCell],
          message,
        },
      };

      toggleEdit();
      await handleSave({ key: record.key, ...adjustments });
      return { ...record, ...values };
    } catch (errInfo) {
      return errInfo;
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required,
            message: `${requireMessage || title} is required.`,
          },
        ]}
      >
        <Input
          ref={inputRef}
          // onPressEnter={save}
          autoComplete="off"
          onBlur={save}
          min={0}
          type="number"
          style={{ maxWidth: 120 }}
          // suffix={
          //   suffix || (
          //     <div
          //       id="comment-suffix"
          //       role="button"
          //       tabIndex={-1}
          //       className="border-l flex-col justify-center items-center pl-1"
          //     >
          //       <Popconfirm
          //         id="adjustment-popconfirm"
          //         icon={null}
          //         title={
          //           <TextArea
          //             value={message}
          //             allowClear
          //             placeholder="Write the reason for the adjustment"
          //             autoSize={{ minRows: 2, maxRows: 4 }}
          //             className="border-w-2 rounded-sm"
          //             onChange={(e) => setMessage(e.target.value)}
          //           />
          //         }
          //         onConfirm={save}
          //         onCancel={() => null}
          //         okText="Save"
          //         cancelText="Cancel"
          //         okButtonProps={{
          //           className: 'border-w-2 rounded-sm',
          //         }}
          //         cancelButtonProps={{
          //           className: 'border-w-2 rounded-sm',
          //         }}
          //       >
          //         <GoComment
          //           onClick={(e) => {
          //             e.stopPropagation();
          //           }}
          //           title="Leave a comment"
          //         />
          //       </Popconfirm>
          //     </div>
          //   )
          // }
        />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 30,
          maxWidth: 120,
          overflow: 'hidden',
        }}
        onClick={toggleEdit}
        onKeyDown={() => null}
        role="button"
        tabIndex={0}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

EditableCell.defaultProps = {
  required: false,
  suffix: null,
  loading: false,
};
