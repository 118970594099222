import React from 'react';
import { Menu } from 'antd';

const DownloadActions = () => {
  const urlPrefix =
    process.env.NODE_ENV === 'production' ? '/public' : '';

  return (
    <Menu className="border rounded-md">
      <Menu.Item key="download actuals template">
        <a
          href={`${urlPrefix}/templates/Actuals_Upload_Template.xlsx`}
          download
          // target="_blank"
          // rel="noopener noreferrer"
        >
          Actuals template
        </a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="download forecast template">
        <a
          href={`${urlPrefix}/templates/Forecast_Upload_Template.xlsx`}
          download
          // target="_blank"
          // rel="noopener noreferrer"
        >
          Forecast template
        </a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="download budget template">
        <a
          href={`${urlPrefix}/templates/Budget_Upload_Template.xlsx`}
          download
          // target="_blank"
          // rel="noopener noreferrer"
        >
          Budget template
        </a>
      </Menu.Item>
    </Menu>
  );
};

export default DownloadActions;
