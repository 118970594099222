export const graphColors = [
  '#004688',
  '#9DC3E7',
  '#04abd3',
  '#dc3545',
  '#ffc107',
  '#17a2b8',
  '#007bff',
  '#343a40',
  '#990099',
  '#8c7a99',
  '#483d8b',
  '#004730',
  '#344611',
  '#800000',
  '#008080',
  '#00E096',
  '#F69DAF',
  '#ff7f50',
  '#ff4500',
  '#BDBD00',
  '#34282c',
  '#4863a0',
  '#15317e',
  '#2418CD',
  '#82caff',
  '#b7ceec',
  '#566d7e',
  '#9EC200',
  '#9dc209',
  '#28a745',
  '#4158d0',
];
