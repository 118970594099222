import { Layout, Result, Row, Select, Skeleton, Space } from "antd";
import React, { Key, useCallback, useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  ForecastReportType, ForecastSelectionType, GranularitySelectionType,
  HorizonRangeDataState, HorizonRangeState, LagSelectionState,
  ReportDataState,
  ReportTableData,
  selectedKeysState,
  TreeDataState
} from "app/bloc/atoms";
import {
  getFetchDataTreePayload,
  getDaysBetweenDates,
  getMonthsBetweenDates,
  getGranularityDateFormatDisplay, getGranularityDay, getGranularity, getWeeksBetweenDates
} from "app/utils/helpers";
import ENDPOINTS from "app/Services/endpoints";
import ForecastHorizon from "app/__portions/Selectors/ForecastHorizon";
import Api from "app/Services";
import { IResponseAccuracyData, IResponseWaterfallData, RowType, TAny } from "app/typings";
import Scaffold from "app/__portions/Scaffold";
import {
  DAILY_DATE_FORMAT,
  MONTHLY_DATE_FORMAT,
  PREVIOUS_HISTORY,
  WEEKLY_DATE_FORMAT
} from "app/utils/helpers/constants";
import ForecastReport from "./ForecastReport";
import LocalStorage from "../../utils/helpers/LocalStorage";

const { Option } = Select;

const Report = () => {
  const printRef = useRef<HTMLDivElement>(null)
  const [reportData, setReportData] = useRecoilState(ReportTableData);

  const treeDataState  = useRecoilValue(TreeDataState);
  const horizonRange   = useRecoilValue(HorizonRangeDataState);
  const [, setHorizon] = useRecoilState(HorizonRangeState);
  const [{ loading, hasLoaded }, setReport] = useRecoilState(ReportDataState);

  const [{ reportKeys }, setSelectedKeys] = useRecoilState(selectedKeysState);
  const forecastSelection = useRecoilValue(ForecastSelectionType);
  const granularitySelection = useRecoilValue(GranularitySelectionType);
  const forecastType = useRecoilValue(ForecastReportType);

  const lagSelection = useRecoilValue(LagSelectionState);

  const [lag, setLag] = useState('');
  const [responseBody, setResponseBody] = useState<IResponseAccuracyData|IResponseWaterfallData>();

  const [historyLength, setHistoryLength] = useState(3);

  const getColumnRanges = () => {
    switch (getGranularity()) {
      case "date": return getDaysBetweenDates(
        moment(horizonRange[0], DAILY_DATE_FORMAT),
        moment(horizonRange[1], DAILY_DATE_FORMAT),
      )
      case "week": return getWeeksBetweenDates(
        moment(horizonRange[0], WEEKLY_DATE_FORMAT),
        moment(horizonRange[1], WEEKLY_DATE_FORMAT),
      )
      case "month": return getMonthsBetweenDates(
        moment(horizonRange[0], MONTHLY_DATE_FORMAT),
        moment(horizonRange[1], MONTHLY_DATE_FORMAT),
      )
      default: return getDaysBetweenDates(
        moment(horizonRange[0], DAILY_DATE_FORMAT),
        moment(horizonRange[1], DAILY_DATE_FORMAT),
      )
    }
  }

  const getMadeOnDays = (madeOnStartDay: moment.Moment, madeOnEndDay: moment.Moment) => {
    switch (getGranularity()) {
      case "date": return getDaysBetweenDates(madeOnStartDay, madeOnEndDay)
      case "week": return getWeeksBetweenDates(madeOnStartDay, madeOnEndDay)
      case "month": return getMonthsBetweenDates(madeOnStartDay, madeOnEndDay)
      default: return getDaysBetweenDates(madeOnStartDay, madeOnEndDay)
    }
  }

  const fetchData = useCallback(async (lines:Record<string, TAny>[] = treeDataState.treeDataResponse) => {
        const payload = {
          lines,
          start_date      : `${horizonRange[0]}`,
          end_date        : `${horizonRange[1]}`,
          report          : forecastType,
          lag_number      : lagSelection,
          forecast_type   : forecastSelection,
          granularity_type: granularitySelection,
        };

        setReport((prev) => {
          return { ...prev, loading: true };
        });

        const { data: responseData } = await Api.post(
          ENDPOINTS.FETCH_DATA,
          payload
        );

        if (responseData) {
          const columnsRange = getColumnRanges()

          const { forecast, actual, metrics, leads } = responseData as IResponseAccuracyData|IResponseWaterfallData;

          setLag(columnsRange[0])
          setResponseBody(responseData)
          setHorizon(columnsRange)

          const actualRow = {
            key: 'actual highlight',
            reportTitle: 'Actual',
            ...actual,
          };

          //  Filter to get the waterfall with lead time
          const madeOnStartDay = moment(horizonRange[0]).subtract(lagSelection, getGranularityDay());
          const madeOnEndDay   = moment(horizonRange[1]).subtract(lagSelection, getGranularityDay());

          const madeOnDays = getMadeOnDays(madeOnStartDay, madeOnEndDay)

          const forecastRows = [] as RowType[];

          madeOnDays.forEach((day, index) => {
            const row = forecast[day];
            const value = row?.[columnsRange[index]];
            if (row && value !== undefined) {
              const madeOnRow = {
                key: `made on ${day}`,
                reportTitle: day,
                // @ts-ignore
                ...row,
                [columnsRange[index]]: {
                  highlight: true,
                  value,
                },
              } as RowType;
              forecastRows.push(madeOnRow);
              delete forecast[day];
            }
          });

          // ADD HISTORY
          const historyRows = Object.keys(forecast)
              .sort((a, b) => moment(a, getGranularityDateFormatDisplay()).isAfter(moment(b, getGranularityDateFormatDisplay())) ? 1 : -1)
              .map((day) => {
                const row = forecast[day];
                return {
                  key: `made on ${day} bg-gray`,
                  reportTitle: day,
                  // @ts-ignore
                  ...row,
                } as RowType;
              });

          // Lead time row
          let leadRow;
          if (Object.keys(leads).length) {
            leadRow = {
              key: 'lead highlight',
              reportTitle: 'Lag',
              ...leads,
            };
          }

          let metricRows;

          if(forecastType === 'over_history') {
            const selectedDate = moment(horizonRange[0]).add(lagSelection - 1, getGranularityDay());
            const lagDay = selectedDate.format(getGranularityDateFormatDisplay());

            // metrics rows
            metricRows = Object.keys(metrics).map((key) => {
              const value = metrics[key][lagDay];

              return {
                key: `metrics ${key}`,
                reportTitle: key,
                ...metrics[key],
                [lagDay]: {
                  highlight: value !== undefined,
                  value,
                },
              };
            });
          } else {

            // Metrics rows
            metricRows = Object.keys(metrics).map((key) => {
              const horizonFirstMonth = columnsRange[0];
              const value = metrics[key][horizonFirstMonth];
              return {
                key: `metrics ${key}`,
                reportTitle: key,
                ...metrics[key],
                [horizonFirstMonth]: {
                  highlight: value !== undefined,
                  value: metrics[key][horizonFirstMonth],
                },
              };
            });

          }

          setReportData((prevData) => ({
            ...prevData,
            actualRow,
            forecastRows,
            historyRows,
            leadRow,
            metricRows: leadRow ? metricRows : undefined,
            dataLoaded: true,
            forecastEvol: forecast,
          }));
        }
      },
      [
        treeDataState.treeDataResponse,
        horizonRange,
        forecastSelection,
        forecastType,
        setReport,
        lagSelection,
        setReportData,
      ],
  );

  useEffect(() => {
    if (reportData.dataLoaded && historyLength) {
      const historyRows = {
        key: 'forecastHistory bg-gray',
        reportTitle: (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start'}}>
            <Row>
              <Space>
              <span style={{ whiteSpace: 'nowrap' }}>
                Previous Forecasts
              </span>
                <Select onChange={setHistoryLength} defaultValue={historyLength}>
                  {PREVIOUS_HISTORY.map((size) => (
                    <Option key={size} value={size}>
                      {size}
                    </Option>
                  ))}
                </Select>
              </Space>
            </Row>
          </div>
        ),
        children: reportData.historyRows?.slice(0, historyLength),
      };

      const data = [] as RowType[];
      data.push(reportData.actualRow);

      if (forecastType === 'over_history' && reportData.historyRows.length) {
        data.push(historyRows);
      }

      if (forecastType === 'over_history') {
        data.push(...reportData.forecastRows);
      } else {
        data.push({
          key: 'forecast highlight',
          reportTitle: 'Forecasts',
          ...reportData.forecastEvol,
        });
      }

      data.push({ key: 'empty' });

      if (forecastType === 'over_items') {
        data.push({
          key: 'currentMetricDay',
          reportTitle: `Metrics for lag ${lagSelection}`,
          className: 'text-gray text-xs',
        });
      }

      if (reportData.leadRow && forecastType === 'over_history') {
        data.push({
          key: 'currentMetricDay',
          reportTitle: `Metrics for ${lag}`,
          className: 'text-gray text-xs',
        });
      }

      if (reportData.leadRow && forecastType === 'over_history') {
        data.push(reportData.leadRow);
      }

      if (reportData.metricRows) {
        data.push(...reportData.metricRows);
      }

      setReport((preValue) => {
        return {
          ...preValue,
          data,
          historyLoaded: true,
          loading: false,
          hasLoaded: true,
        };
      });
    }
  }, [reportData, historyLength, forecastType, setReport, lag, lagSelection]);

  useEffect(() => {
    if (!hasLoaded && horizonRange.length && treeDataState.treeDataResponse.length) {
      if(LocalStorage.getTreeSelection() !== null && LocalStorage.getSelectedTreeKey() !== null){
        // @ts-ignore
        fetchData(JSON.parse(LocalStorage.getTreeSelection()))
      } else {
        fetchData()
      }
    }
  }, [
    horizonRange.length,
    hasLoaded,
    // horizonRange,
    fetchData,
    treeDataState.treeDataResponse,
  ]);

  const onSelect = (keys: Key[], info: TAny) => {
    setSelectedKeys((prev) => ({ ...prev, reportKeys: keys }))

    const payload = getFetchDataTreePayload(
        info.node,
        treeDataState.treeDataResponse
    )

    LocalStorage.setTreeSelection(JSON.stringify(payload))
    LocalStorage.setSelectedTreeKey(JSON.stringify(keys))

    fetchData(payload)
  }

  const onOpenChange = (open: boolean) => {
    if (horizonRange.length && treeDataState.treeDataResponse.length) {
      if (!open) {
        if(LocalStorage.getTreeSelection() !== null && LocalStorage.getSelectedTreeKey() !== null){
          // @ts-ignore
          fetchData(JSON.parse(LocalStorage.getTreeSelection()))
        } else {
          fetchData()
        }
      }
    }
  }

  return (
      <Scaffold selectedKeys={reportKeys} onSelect={onSelect}>
        {(hasLoaded && treeDataState.treeData.length === 0) && (
            <Layout>
              <div className="p-2 md:p-4 pb-16 overflow-y-auto w-full">
                <Result
                    status="404"
                    title='Empty Data'
                    subTitle="Sorry, it seems there is no data found. Please try again later..."
                    extra={
                      <Link to="/" type="primary">
                        Refresh Page
                      </Link>
                    }
                />
              </div>
            </Layout>
        ) || (
          <div ref={printRef}>
            <Layout>
              {loading && (
                  <Layout>
                    <Layout style={{
                      display: 'flex',
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between"
                    }}>
                      <Skeleton.Input style={{width: 175, borderRadius: 8}} active size="small"/>
                      <div style={{display: 'flex', flexDirection: "column", gap: 2}}>
                        <Skeleton.Input style={{width: 100, borderRadius: 8}} active size="small"/>
                        <Skeleton.Input style={{width: 230, borderRadius: 8}} active size="large"/>
                      </div>
                    </Layout>
                  </Layout>
              ) || (
                    <Layout style={{
                      display: 'flex',
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between"
                    }}>
                      <h1 className="text-lg font-bold letter-28 text-black">
                        {forecastType === 'over_history' ? 'WATERFALL REPORT' : 'FORECAST ACCURACY EVOLUTION'}
                      </h1>
                      <ForecastHorizon
                        value={[ moment(horizonRange[0]), moment(horizonRange[1])]}
                        onOpenChange={onOpenChange}
                      />
                    </Layout>
              )}
              <ForecastReport data={responseBody}/>
            </Layout>
          </div>
        )}
      </Scaffold>
  );
};

export default Report;
