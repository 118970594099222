import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import Routes from 'app/routes';
import routes from 'app/routes/_routes';

import 'app/assets/styles/style.css';

// import { ConfigProvider } from "antd";

const App = () => {
  // ConfigProvider.config({
  //   theme: {
  //     primaryColor: '#EEB718',
  //     infoColor: '#EEB718',
  //     processingColor: '#EEB718',
  //   },
  // });

  return (
    // <ConfigProvider>
      <RecoilRoot>
        <BrowserRouter>
          <Routes routes={routes} />
        </BrowserRouter>
      </RecoilRoot>
    // </ConfigProvider>
  );
};

export default App;
