import React, { FC, ReactNode } from 'react';
import { USER_ROLES } from 'app/utils/helpers/constants';
import { currentRole } from 'app/utils/configs';

interface IProps {
  fallbackElement?: ReactNode;
  to?: string[];
}

/**
 *
 * @param {to} array => Role of users with no permissions to access the component
 * Available types: admin | sales | planner
 * @returns {ReactNode} children with permission
 */

const RestrictedTo: FC<IProps> = ({
  to,
  fallbackElement,
  children,
}) => {
  const role = currentRole();

  if (!role) return null;

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{to?.includes(role.value) ? fallbackElement : children}</>;
};

RestrictedTo.defaultProps = {
  fallbackElement: null,
  to: [USER_ROLES.Planner.value, USER_ROLES.Sales.value],
};

export default RestrictedTo;
