import React, { useEffect, useRef } from 'react';
import { DatePicker, Space } from 'antd';
import moment from 'moment';
import Scaffold from 'app/__portions/Scaffold';
import useAnalytics from 'app/Hooks/useAnalytics';
import FiscalReportTable from './FiscalReportTable';
import ProjectsReportTable from './ProjectionsReportTable';
import AnalyticsGraph from './AnalyticsGraph';
import ExportReportButton from "../../__portions/ExportFvaButton";

const ReportAnalytics = () => {
  const {
    hasLoaded,
    nodeTitle: regionName,
    loading,
    currentYear,
    graphAnalytics,
    fiscalData,
    actualsMonths,
    forecastMonths,
    projectionData,
    analyticsKeys,
    fetchAnalytics,
    onSelect,
    setAnalyticsData,
  } = useAnalytics();

  const mainWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!hasLoaded) {
      fetchAnalytics();
    }
  }, [fetchAnalytics, hasLoaded]);

  return (
    <Scaffold
      expandedTreeKey="analyticsKeys"
      selectedKeys={analyticsKeys}
      onSelect={onSelect}
    >
      <div className="pb-3 w-full flex ant-space-align-center ant-row-space-between">
        <h1 className="text-lg letter-28 text-black-50">
          BUDGET REPORT
        </h1>
        <Space size="large" align="end">
          <div>
            <div className="text-xs mb-1 text-black-50">
              Filter by
            </div>
            <DatePicker
              picker="year"
              className="rounded-sm border-w-2 border-gray-300 btn-height"
              size="large"
              style={{ minWidth: '250px' }}
              defaultValue={moment(currentYear)}
              allowClear={false}
              onChange={(_, year) => {
                const currentMonth = moment().format('MM');

                setAnalyticsData((state) => ({
                  ...state,
                  hasLoaded: false,
                  currentYear: `${year}-${currentMonth}`,
                }));
              }}
            />
          </div>

          <ExportReportButton className="btn-height" />
        </Space>
      </div>
      <div
        ref={mainWrapperRef}
        className="bg-white px-3 py-4 rounded-md overflow-x-hidden"
      >
        <div className="overflow-x-auto">
          <FiscalReportTable
            data={fiscalData}
            region={regionName as string}
            currentYear={currentYear}
            loading={loading}
          />
        </div>
        <div className="my-5 p-2 border rounded-md">
          <AnalyticsGraph
            data={graphAnalytics}
            region={regionName as string}
          />
        </div>
        <ProjectsReportTable
          data={projectionData}
          region={regionName as string}
          loading={loading}
          currentYear={currentYear}
          previousMonths={actualsMonths}
          nextMonths={forecastMonths}
        />
      </div>
    </Scaffold>
  );
};

export default ReportAnalytics;
