import React, { useEffect, useState } from 'react';
import { Table } from 'antd';

import './style.css';
import { useRecoilValue } from 'recoil';
import moment from 'moment';
import { HorizonRangeDataState } from 'app/bloc/atoms';
import { isObject } from 'app/utils/helpers';
import { DAILY_DISPLAY_FORMAT, MONTHLY_DISPLAY_FORMAT, WEEKLY_DATE_FORMAT } from "app/utils/helpers/constants";
import { TAny } from "../../../typings";

interface Props {
  data?: TAny[];
  granularity?: string;
  loading?: boolean;
}

const bgColor = (value, record) => {
  if (isObject(value) && value.highlight) {
    return 'lightblue';
  }
  if (value !== undefined && record.key === 'actual highlight') {
    return '#04abd3';
  }
  if (record.key?.includes('bg-gray')) {
    return '#fafafa';
  }
  return '';
};

const columns = [
  {
    title: 'Date',
    dataIndex: 'reportTitle',
    render: (text: string, record: TAny) => {
      if (!text) return <div style={{ height: '20px' }} />;
      return record.key?.includes('forecastHistory') ? (
        <div className={`m-0 ${record?.className || ''}`}>{text}</div>
      ) : (
        <p className={`m-0 ${record?.className || ''}`} style={{ fontWeight: record.key?.includes('highlight') ? 'bold' : ''}}>
          {text}
        </p>
      );
    },
    fixed: 'left',
    width: 120,
    className: 'bg-white text-black',
  },
] as Array<Record<string, unknown>>;

const getDaysBetweenDatesString = (start: string, end: string) => {
  const dateStart = moment(start);
  const dateEnd = moment(end);
  const interim = dateStart.clone();
  const timeValues: string[] = [];

  while (dateEnd > interim || interim.format('D') === dateEnd.format('D')) {
    timeValues.push(interim.format(DAILY_DISPLAY_FORMAT));
    interim.add(1, 'day');
  }

  return timeValues;
};

const getWeeklyBetweenDatesString = (start: string, end: string) => {
  const dateStart = moment(start);
  const dateEnd = moment(end);
  const interim = dateStart.clone();
  const timeValues: string[] = [];

  while (dateEnd > interim || interim.format('W') === dateEnd.format('W')) {
    timeValues.push(interim.format(WEEKLY_DATE_FORMAT));
    interim.add(1, 'week');
  }
  return timeValues;
};

const getMonthsBetweenDatesString = (start: string, end: string) => {
  const dateStart = moment(start);
  const dateEnd = moment(end);
  const interim = dateStart.clone();
  const timeValues: string[] = [];

  while (dateEnd > interim || interim.format('M') === dateEnd.format('M')) {
    timeValues.push(interim.format(MONTHLY_DISPLAY_FORMAT));
    interim.add(1, 'month');
  }
  return timeValues;
};

const getDayWeekMonthBetweenDates = (granularity: string, range:string[]) => {
  switch (granularity) {
    case '1': return getDaysBetweenDatesString(range[0], range[1])
    case '2': return getWeeklyBetweenDatesString(range[0], range[1])
    case '3': return getMonthsBetweenDatesString(range[0], range[1])
    default:  return getDaysBetweenDatesString(range[0], range[1])
  }
}

const ReportTable = ({ data, granularity, loading }: Props) => {
  const range = useRecoilValue(HorizonRangeDataState);
  const [tableColumns, setTableColumns] = useState(columns);

  useEffect(() => {
    if (range.length) {
      const days = getDayWeekMonthBetweenDates(granularity!, range)
      setTableColumns(
        columns.concat(
          days.map((day) => {
            return {
              title: day,
              dataIndex: day,
              render: (record: TAny) => {
                if (isObject(record)) return record.value;
                if (record === 0) return 0;
                return record;
              },
              onCell: (record: TAny) => {
                return {
                  style: {
                    backgroundColor: bgColor(record[day], record),
                  },
                };
              },
              width: 40,
            };
          }),
        ),
      );
    }
  }, [range]);

  return (
    <Table
      className="border-none"
      columns={tableColumns}
      dataSource={data}
      pagination={false}
      scroll={{ x: 'max-content' }}
      loading={loading}
    />
  );
};

ReportTable.defaultProps = {
  data: [],
  granularity: '1',
  loading: false,
};

export default ReportTable;
