import React, { FC, useEffect, useState } from 'react';
import { Table } from 'antd';

import './style.css';
import { useRecoilValue } from 'recoil';
import moment from 'moment';
import { HorizonRangeDataState } from 'app/bloc/atoms';
import { isObject } from 'app/utils/helpers';
import { DAILY_DISPLAY_FORMAT } from 'app/utils/helpers/constants';
import { TAny } from "../../../typings";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any[];
  loading?: boolean;
}

/**
 * 1. Ignore colouring the first row that has actual keyword in its key attribute
 * 2. Highlight only cells that have a value less than 1500 and contain actual keyword in their key attribute
 * @param value string
 * @param record object
 * @returns color @string
 */
const bgColor = (value: TAny, record: TAny) => {
  if (isObject(value) && value.highlight) {
    return 'lightblue';
  }
  if (value !== undefined && record.key === 'actual highlight') {
    return '#04abd3';
  }
  if (record.key?.includes('bg-gray')) {
    return '#fafafa';
  }
  return '';
};

const columns = [
  {
    title: 'Date',
    dataIndex: 'reportTitle',
    render: (text: string, record: TAny) => {
      if (!text) return <div style={{ height: '20px', fontWeight: 'bold' }}/>
      return (
        <span className={`m-0 ${record?.className || ''}`} style={{ fontWeight: record.key?.includes('highlight') ? 'bold' : ''}}>
          {text}
        </span>
      );
    },
    fixed: 'left',
    width: 130,
    className: 'bg-white text-black',
  },
] as Array<Record<string, unknown>>;

const getDaysBetweenDatesString = (start: string, end: string) => {
  const dateStart = moment(start);
  const dateEnd = moment(end);
  const interim = dateStart.clone();
  const timeValues: string[] = [];

  while (dateEnd > interim || interim.format('D') === dateEnd.format('D')) {
    timeValues.push(interim.format(DAILY_DISPLAY_FORMAT));
    interim.add(1, 'day');
  }

  return timeValues;
};

const ReportTableFva: FC<Props> = ({ data, loading }: Props) => {
  const range = useRecoilValue(HorizonRangeDataState);
  const [tableColumns, setTableColumns] = useState(columns);

  useEffect(() => {
    if (range.length) {
      const days = getDaysBetweenDatesString(range[0], range[1]);
      setTableColumns(
        columns.concat(
            days.map((day) => {
              return {
                title: day,
                dataIndex: day,
                render: (record: TAny) => {
                  if (isObject(record)) return record.value;
                  if (record === 0) return 0;
                  return record;
                },
                onCell: (record: TAny) => {
                  return { style: { backgroundColor: bgColor(record[day], record) } };
                },
                width: 40,
              };
          }),
        ),
      );
    }
  }, [range]);

  return (
    <Table
      className="border-none"
      columns={tableColumns}
      dataSource={data}
      pagination={false}
      scroll={{ x: 'max-content' }}
      loading={loading}
    />
  );
};

ReportTableFva.defaultProps = {
  data: [],
  loading: false,
};

export default ReportTableFva;
