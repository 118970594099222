import moment from "moment";
import {
  TAny,
  TGranularityDateFormat,
  TGranularityDateFormatDisplay,
  TGranularitySelectionType,
  TGranularityType, TGranularityTypeDay
} from "app/typings";
import {
  DAILY_DATE_FORMAT,
  DAILY_DISPLAY_FORMAT,
  MONTHLY_DATE_FORMAT,
  MONTHLY_DISPLAY_FORMAT,
  WEEKLY_DATE_FORMAT, WEEKLY_DISPLAY_FORMAT
} from "./constants";
import LocalStorage from "./LocalStorage";

const collect =
  (pred:TAny) =>
  (xs = []) =>
    xs.flatMap((x:TAny) => [
      ...(pred(x) ? [x] : []),
      ...collect(pred)(x?.children),
    ]);

export const getSelectedKeys = (treeData:TAny) =>
  collect(({ selected }) => selected)(treeData).map(
    (value:TAny) => value.key,
  );

export const getSelectedNode = (treeData:TAny) =>
  collect(({ selected }) => selected)(treeData).map(
    (value:TAny) => value,
  )[0] || {};

export const isObject = (value:TAny): boolean => {
  return value?.constructor === Object;
};

export const convertToSlug = (region:TAny, revert = false) => {
  if (!region) return null;

  if (revert) {
    return region.replaceAll('_', ' ');
  }

  return region.replaceAll(' ', '_');
};

export const sumArrayObjectsValues = (arr = []) => {
  const result = {};

  arr.forEach((obj) => {
    Object.entries(obj).forEach(([key, value]) => {
      if (result[key]) {
        result[key] += value;
      } else {
        result[key] = value;
      }
    });
  });
  return result;
};

export const analyticsQuarters = {
  Q1: ['Jan', 'Feb', 'Mar'],
  Q2: ['Apr', 'May', 'Jun'],
  Q3: ['Jul', 'Aug', 'Sep'],
  Q4: ['Oct', 'Nov', 'Dec'],
};

export const aggregateQuarters = (data:TAny, year:TAny, field = '') => {
  const obj = {} as TAny;
  let currFiscTotal = 0;
  let nextFiscTotal = 0;

  const dataKeys = Object.keys(analyticsQuarters);

  if (!Object.keys(data).length) {
    return {};
  }

  dataKeys.forEach((quarterKey, index) => {
    let currQuarterTotal = 0;
    let nextQuarterTotal = 0;

    analyticsQuarters[quarterKey].forEach((quarter:TAny) => {
      if (data[`${year}-${quarter}`]) {
        currQuarterTotal += data[`${year}-${quarter}`];
      }
      if (data[`${+year + 1}-${quarter}`]) {
        nextQuarterTotal += data[`${+year + 1}-${quarter}`];
      }
    });

    obj[`cfy${quarterKey}`] = currQuarterTotal;
    obj[`nfy${quarterKey}`] = nextQuarterTotal;

    currFiscTotal += currQuarterTotal;
    nextFiscTotal += nextQuarterTotal;

    obj.cfyFY = currFiscTotal;
    obj.nfyFY = nextFiscTotal;
    obj.key   = `${field}-${quarterKey}-${index}`;
    obj.field = field === 'Id' ? 'ID' : field || undefined;
  });

  return obj;
};

export const getDaysBetweenDates = (
  dateStart: moment.Moment,
  dateEnd: moment.Moment,
  format = DAILY_DISPLAY_FORMAT,
) => {
  const interim = dateStart.clone();
  const timeValues: string[] = [];

  while (dateEnd > interim || interim.format('D') === dateEnd.format('D')) {
    timeValues.push(interim.format(format));
    interim.add(1, 'day');
  }
  return timeValues;
};

export const getWeeksBetweenDates = (
  dateStart: moment.Moment,
  dateEnd: moment.Moment,
  format = WEEKLY_DISPLAY_FORMAT,
) => {
  const interim = dateStart.clone();
  const timeValues: string[] = [];

  while (
    dateEnd > interim ||
    interim.format('W') === dateEnd.format('W')
    ) {
    timeValues.push(interim.format(format));
    interim.add(1, 'week');
  }
  return timeValues;
};

export const getMonthsBetweenDates = (
  dateStart: moment.Moment,
  dateEnd: moment.Moment,
  format = MONTHLY_DISPLAY_FORMAT,
) => {
  const interim = dateStart.clone();
  const timeValues: string[] = [];

  while (
    dateEnd > interim ||
    interim.format('M') === dateEnd.format('M')
    ) {
    timeValues.push(interim.format(format));
    interim.add(1, 'month');
  }
  return timeValues;
};

export const getFetchDataTreePayload = (node: TAny, data: TAny = [], hasSelected = false ): TAny[] => {
  const { type, name, lineName, regionName } = node;
  const isAllProducts = type === 'all-products';

  return data.map((line: TAny) => {
    const selectedLine = isAllProducts || (type === 'line' && line.name === name);

    const regions = line.regions.map((reg: TAny) => {
      const isRegion =
        type === 'region' &&
        line.name === lineName &&
        reg.name === name;

      const selectedRegion = hasSelected
        ? isRegion
        : selectedLine || isRegion;

      const groups = reg.groups.map((group: TAny) => {
        const isGroup =
          type === 'group' &&
          line.name === lineName &&
          reg.name === regionName &&
          group.name === name;

        const selectedGroup = hasSelected
          ? isGroup
          : selectedRegion || isGroup;

        const items = group.items.map((item: TAny) => {
          const isItem =
            type === 'item' &&
            line.name === lineName &&
            reg.name === regionName &&
            item.name === name;

          const selectedItem = hasSelected
            ? isItem
            : selectedGroup || isItem;

          return { ...item, selected: selectedItem };
        });
        return { ...group, selected: selectedGroup, items };
      });
      return { ...reg, selected: selectedRegion, groups };
    });
    return { ...line, selected: selectedLine, regions };
  });
};


export const sortByDateAfter = (unordered = {}) => {
  return Object.keys(unordered)
    .sort((a, b) =>
      moment(a, DAILY_DISPLAY_FORMAT).isAfter(moment(b, DAILY_DISPLAY_FORMAT))
        ? 1
        : -1,
    )
    .reduce((ordered, key) => {
      ordered[key] = unordered[key];
      return ordered;
    }, {});
};

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const getPreviousDays = (
  days: number,
  format = DAILY_DATE_FORMAT,
) => {
  if (days < 1) return [];

  const start = moment().startOf('day');

  return [
    start.format(format),
    ...Array.from({ length: days - 1 }).map(() =>
      start.subtract(1, 'day').format(format),
    ),
  ];
};

// export const getPreviousMonths = (
//   months: number,
//   format = DEMAND_API_DATE_FORMAT_DAY,
// ) => {
//   if (months < 1) return [];
//
//   const start = moment().startOf('day');
//
//   return [
//     start.format(format),
//     ...Array.from({ length: months - 1 }).map(() =>
//       start.subtract(1, 'day').format(format),
//     ),
//   ];
// };

export const getPreviousDay = (format = DAILY_DATE_FORMAT) => {
  return moment()
    .subtract(1, 'day')
    .startOf('day')
    .format(format);
};

// export const getPreviousMonth = (format = DEMAND_API_DATE_FORMAT_DAY) => {
//   return moment()
//     .subtract(1, 'month')
//     .startOf('month')
//     .format(format);
// };

export const toUser = (datum) => ({
  key : datum.id,
  info: {
    firstName: datum.first_name,
    lastName : datum.last_name,
    avatarUrl: datum.profile_image,
  },
  role   : datum.role,
  email  : datum.email,
  isStaff: datum.is_staff,
});

export const staleAndWait = (ms = 1000) => {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const isEmpty = (obj: object) =>
  obj &&
  Object.keys(obj).length &&
  Object.getPrototypeOf(obj) === Object.prototype;

function formatDate(dateString: string) {
  const months = {
    'Jan': '01',
    'Feb': '02',
    'Mar': '03',
    'Apr': '04',
    'May': '05',
    'Jun': '06',
    'Jul': '07',
    'Aug': '08',
    'Sep': '09',
    'Oct': '10',
    'Nov': '11',
    'Dec': '12'
  };

  const [year, month, day] = dateString.split('-');
  const formattedMonth = months[month];
  return `${year}-${formattedMonth}-${day}`;
}


export const toAdjPayload = (
  forecast:
    | Record<string, number>
    | Record<string, Record<string, string>>
    | undefined,
) => {
  if (!forecast) return [];

  return Object.entries(forecast).map(([key, item]) => {
    return ({
      date: formatDate(key),
      value: Number.parseInt(item?.value || item, 10),
      message: item?.message || undefined
    });
  });
};

export class ValidationError extends Error {
  constructor(message) {
    super(message);
    this.name = 'ValidationError';
  }
}

export const getSelectedGranularity = (index: string) => {
  let granularity:TGranularitySelectionType;
  switch (index) {
    case '1':
      granularity = 'daily'
      break;
    case '2':
      granularity = 'weekly'
      break;
    case '3':
      granularity = 'monthly'
      break;
    default: granularity = 'daily'
  }

  return granularity
}

export const getGranularity = () => {
  let granularity:TGranularityType;
  const index = LocalStorage.getGranularity()
  switch (index) {
    case '1':
      granularity = 'date'
      break;
    case '2':
      granularity = 'week'
      break;
    case '3':
      granularity = 'month'
      break;
    default: granularity = 'date'
  }

  return granularity
}

export const getGranularityDay = () => {
  let granularity:TGranularityTypeDay;
  const index = LocalStorage.getGranularity()
  switch (index) {
    case '1':
      granularity = 'day'
      break;
    case '2':
      granularity = 'week'
      break;
    case '3':
      granularity = 'month'
      break;
    default: granularity = 'day'
  }

  return granularity
}

export const getGranularityDateFormat = () => {
  let granularity:TGranularityDateFormat;
  const index = LocalStorage.getGranularity()
  switch (index) {
    case '1':
      granularity = DAILY_DATE_FORMAT
      break;
    case '2':
      granularity = WEEKLY_DATE_FORMAT
      break;
    case '3':
      granularity = MONTHLY_DATE_FORMAT
      break;
    default: granularity = DAILY_DATE_FORMAT
  }

  return granularity
}

export const getGranularityDateFormatDisplay = () => {
  let granularity:TGranularityDateFormatDisplay;
  const index = LocalStorage.getGranularity()
  switch (index) {
    case '1':
      granularity = DAILY_DISPLAY_FORMAT
      break;
    case '2':
      granularity = WEEKLY_DISPLAY_FORMAT
      break;
    case '3':
      granularity = MONTHLY_DISPLAY_FORMAT
      break;
    default: granularity = DAILY_DISPLAY_FORMAT
  }

  return granularity
}
