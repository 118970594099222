import { AutoComplete, Table } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { HiOutlineSearch } from "react-icons/hi";
import { INotification } from "../../../../typings";

type Props = {
  loading?: boolean;
  data?: INotification[];
}

const NotificationTable = ({ loading, data }:Props) => {

  const [dataSource, setDataSource] = useState(data);

  const columns = useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        onHeaderCell: () => ({ className: 'bg-transparent' }),
      },
      // {
      //   title: 'NAME',
      //   dataIndex: 'file_name',
      //   onHeaderCell: () => ({ className: 'bg-transparent' }),
      //   render: (details, _) => {
      //     if (details && Object.keys(details).length) {
      //       return (
      //         <div
      //           role="button"
      //           tabIndex={0}
      //           onKeyDown={() => null}
      //           className="ant-row ant-row-no-wrap ant-space-align-center"
      //           onClick={() => {}}
      //         >
      //           {/* <Avatar */}
      //           {/*   src={details.avatarUrl} */}
      //           {/*   size="large" */}
      //           {/*   className="flex-shrink-0 border" */}
      //           {/* /> */}
      //           <div className="flex-1 ml-2">
      //             {details.file_name}
      //           </div>
      //         </div>
      //       );
      //     }
      //     return details;
      //   },
      // },
      {
        title: 'NAME',
        dataIndex: 'file_name',
        onHeaderCell: () => ({ className: 'bg-transparent' }),
      },
      {
        title: 'TYPE',
        dataIndex: 'upload_type',
        onHeaderCell: () => ({ className: 'bg-transparent' }),
      },
      {
        title: 'DATE',
        dataIndex: 'created_at',
        onHeaderCell: () => ({ className: 'bg-transparent' }),
      },
      // {
      //   title: 'STATUS',
      //   key: 'status',
      //   dataIndex: 'status',
      //   render: (_, { tags }) => (
      //     <>
      //       {tags.map(tag => {
      //         return (
      //           <Tag color={getColor(tag)} key={tag}>
      //             {tag.toUpperCase()}
      //           </Tag>
      //         );
      //       })}
      //     </>
      //   ),
      // },
    ],
    [loading, dataSource],
  );

  const options = useMemo(() => {
    return dataSource!
      .filter((datum) => datum.file_name)
      .map((source) => ({
        value: `${source.file_name} ${source.upload_type}`,
        label: `${source.file_name} ${source.upload_type}`,
      }));
  }, [dataSource]);

  const onSearch = (query: string) => {
    if (query) {
      const filtered = data!.filter((datum) => {
        const fullName = `${datum.file_name}`;

        return fullName
          .toLocaleLowerCase()
          .includes(query.toLocaleLowerCase());
      });

      setDataSource(filtered);
      return;
    }
    setDataSource(data);
  };

  useEffect(() => {
    setDataSource(data);
  }, [data]);

  // const getColor = (status: string) => {
  //   let color: string
  //   switch (status) {
  //     case 'CU':
  //       color = 'blue'
  //       break
  //     case 'PR':
  //       color = 'brown'
  //       break;
  //     case 'PA':
  //       color = 'green'
  //       break
  //
  //     default: color = 'blue'
  //   }
  //
  //   return color
  // }

  return (
    <>
      <div className="mb-3 w-[200px] xs:w-full">
        <AutoComplete
          allowClear
          options={options}
          placeholder={
            <div className="flex justify-center items-center absolute">
              <HiOutlineSearch size="19.2px" />
              <span className="ml-2">
                Search for a user by first name or last name
              </span>
            </div>
          }
          className="btn-height w-full autocomplete-input rounded-sm"
          onSearch={onSearch}
        />
      </div>
      <Table
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        className="bg-white"
        scroll={{ x: '120px' }}
      />
    </>
  );
}

NotificationTable.defaultProps = {
  loading: false,
  data: []
}

export default NotificationTable