export const spaceRemover = (data?:string|null) => {
  const lower = data?.toLowerCase();
  const upper = data?.toUpperCase();

  let res = "";
  for(let i=0; i<lower!.length; i+=1) {
    if(lower![i] !== upper![i] || lower![i].trim() === '')
      res += data![i];
  }
  return res;
}