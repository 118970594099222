import React, { FC, useState } from "react";
import { useRecoilValue } from "recoil";
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

import {
  FvaDataState,
  HorizonRangeDataState,
  HorizonRangeState,
  LagSelectionState,
  TreeDataState
} from "app/bloc/atoms";
import ExportFvaButton from 'app/__portions/ExportFvaButton';
import { IResponseFvaData } from "../../../typings";
import LocalStorage from "../../../utils/helpers/LocalStorage";
import ReportTableFva from "../ReportTableFva";

type Props = {
  data   : IResponseFvaData | undefined;
};

const ForecastReportFva: FC<Props> = ({ data }) => {
  const reportFva = useRecoilValue(FvaDataState)

  const horizonRange = useRecoilValue(HorizonRangeDataState);
  const { loading: treeLoading } = useRecoilValue(TreeDataState)
  const [exporting, setExporting] = useState(false)
  const lagSelection = useRecoilValue(LagSelectionState)
  const horizon = useRecoilValue(HorizonRangeState);

  const spaceRemover = (data?:string|null) => {
    const lower = data?.toLowerCase();
    const upper = data?.toUpperCase();

    let res = "";
    for(let i = 0; i < lower!.length; i += 1) {
      if(lower![i] !== upper![i] || lower![i].trim() === '')
        res += data![i];
    }
    return res;
  }

  const onFvaExport = () => {
    try {
      setExporting(true)

      const wsData = horizon.map((date) => ({
        Date           : date,
        Actual         : data?.actual               [date] || '-',
        Stat           : data?.statistical_forecast [date] || '-',
        Planner        : data?.planner_forecast     [date] || '-',
        Sales          : data?.sales_rep_forecast   [date] || '-',
        'Stat MAPE'    : data?.statistical_mape     [date] || '-',
        'Planner MAPE' : data?.planner_mape         [date] || '-',
        'Sales MAPE'   : data?.sales_rep_mape       [date] || '-',
        'Fva Planner'  : data?.fva_sales_rep_mape   [date] || '-',
        'Fva Sales'    : data?.fva_planner_mape     [date] || '-',
      }));

      const ws = XLSX.utils.json_to_sheet(wsData);
      const wb = XLSX.utils.book_new();

      XLSX.utils.sheet_add_aoa(ws, [["PRODUCT:"]], { origin: 'J2' });
      XLSX.utils.sheet_add_aoa(ws, [[spaceRemover(LocalStorage.getSelectedTreeKey())]], { origin: 'K2' });

      XLSX.utils.sheet_add_aoa(ws, [["LAG:"]], { origin: 'J3' });
      XLSX.utils.sheet_add_aoa(ws, [[lagSelection]], { origin: 'K3' });

      XLSX.utils.sheet_add_aoa(ws, [["FROM:"]], { origin: 'J4' });
      XLSX.utils.sheet_add_aoa(ws, [[horizonRange[0]]], { origin: 'K4' });

      XLSX.utils.sheet_add_aoa(ws, [["TO:"]], { origin: 'J5' });
      XLSX.utils.sheet_add_aoa(ws, [[horizonRange[1]]], { origin: 'K5' });

      XLSX.utils.sheet_add_aoa(ws, [["TYPE:"]], { origin: 'J6' });
      XLSX.utils.sheet_add_aoa(ws, [['FORECAST VALUE ADDED']], { origin: 'K6' });

      XLSX.utils.book_append_sheet(wb, ws, 'forecast_value_added');

      const payload = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      setExporting(false)
      saveAs(new Blob([payload], { type: "application/octet-stream" }), `Forecast-Value-Added-From-${horizonRange[0]}-To-${horizonRange[1]}.xlsx`);
    } catch (e) {
      setExporting(false)
      console.error(e)
    }
  };

  // @ts-ignore
  return (
    <div className="bg-white shadow-sm rounded-md p-5 my-5">
      <div className="px-2 ant-row justify-between">
        <div className="ant-rowr row-align-end">
          <ExportFvaButton
            onExportToExcel={onFvaExport}
            disabled={exporting}
            loading={exporting}
            data={data}
          />
        </div>
      </div>
      <div className="py-2">
        <ReportTableFva
          data={reportFva.data}
          loading={treeLoading || reportFva.loading}
        />
      </div>
    </div>
  );
};

export default ForecastReportFva;
