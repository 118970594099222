import ReportAnalytics from 'app/pages/Analytics';
import Login from 'app/pages/Login';
import Report from 'app/pages/Report';
import IRoute from 'app/typings';
import paths from 'app/routes/paths';
import DemandPlanning from 'app/pages/DemandPlanning';
import UsersActivity from 'app/pages/Users';
import { USER_ROLES } from 'app/utils/helpers/constants';
import CapacityDemand from 'app/pages/CapacityPlanning';
import ForecastValueAdded from "../pages/ForecastValueAdded";
import Settings from "../pages/Settings";
import Profile from "../pages/Settings/Profile";
import Notification from "../pages/Settings/Notification";
import Users from "../pages/Settings/Users";

const routes: IRoute[] = [
  {
    name: 'Biocartis - Login',
    secured: false,
    path: paths.HOME_PATH,
    exact: true,
    component: Login,
  },
  {
    name: 'Biocartis - Report',
    secured: true,
    path: paths.REPORT_PATH,
    exact: true,
    component: Report,
  },
  {
    name: 'Biocartis - Report Analytics',
    secured: true,
    path: [paths.REPORT_ANALYTICS, paths.REPORT_ANALYTICS_SUB],
    exact: true,
    component: ReportAnalytics,
    roles: [USER_ROLES.Admin.value, USER_ROLES.Planner.value],
  },
  {
    name: 'Biocartis - Demand Planning',
    secured: true,
    path: paths.DEMAND_PLANNING,
    exact: true,
    component: DemandPlanning,
  },
  {
    name: 'Biocartis - Forecast Value Added',
    secured: true,
    path: paths.FORECAST_VALUE_ADDED,
    exact: true,
    component: ForecastValueAdded,
  },
  {
    name: 'Biocartis - Users',
    secured: true,
    path: paths.USERS_ACTIVITY,
    exact: true,
    component: UsersActivity,
    roles: [USER_ROLES.Admin.value],
  },
  {
    name: 'Biocartis - Capacity',
    secured: true,
    path: paths.CAPACITY_DEMAND,
    exact: true,
    component: CapacityDemand,
    roles: [USER_ROLES.Admin.value],
  },
  {
    name: 'Biocartis - Settings',
    secured: true,
    path: paths.SETTINGS,
    exact: true,
    component: Settings,
  },
  {
    name: 'Biocartis - Settings - Users',
    secured: true,
    path: paths.SETTINGS_USERS,
    exact: true,
    component: Users,
    roles: [USER_ROLES.Admin.value],
  },
  {
    name: 'Biocartis - Settings - Profile',
    secured: true,
    path: paths.SETTINGS_PROFILE,
    exact: true,
    component: Profile,
    roles: [USER_ROLES.Admin.value],
  },
  {
    name: 'Biocartis - Settings - Notifications',
    secured: true,
    path: paths.SETTINGS_NOTIFICATION,
    exact: true,
    component: Notification,
    roles: [USER_ROLES.Admin.value],
  },
];

export default routes;
