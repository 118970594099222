import React from 'react';
import { Empty } from 'antd';
import {
  Chart as ChartJS,
  LineElement,
  BarElement,
  PointElement,
  CategoryScale,
  LinearScale,
  LineController,
  Legend,
  Title,
  Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { TAny } from 'app/typings';

ChartJS.register(
  LineElement,
  BarElement,
  PointElement,
  CategoryScale,
  LinearScale,
  LineController,
  Legend,
  Title,
  Tooltip,
);

const options = {
  type: 'line',
  responsive: true,
  plugins: {
    legend: {
      position: 'right' as const,
    },
    title: {
      display: true,
      text: 'EU Forecast',
    },
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

interface Props {
  data: TAny;
  region: string;
}

const AnalyticsGraph = ({ data, region }: Props) => {
  options.plugins.title.text = `${region} Forecast`;

  if (!data?.datasets?.length) {
    return <Empty description="Graph will be displayed here" />;
  }

  return <Bar options={options} data={data} />;
};

export default AnalyticsGraph;
