import React from "react";
import { Avatar, Button } from "antd";
import { useRecoilState } from "recoil";
import { UsersState } from "../../../bloc/atoms";
import Scaffold from "../../../__portions/Scaffold";
import './styles.css';
import SettingSideMenu from "../../../__portions/SideMenus/SettingSideMenu";

const Profile = () => {
  const [{ currentUser }] = useRecoilState(UsersState);
  return (
    <Scaffold sideBar={<SettingSideMenu />}>
      <div className="ant-row ant-row-space-between ant-space-align-center mb-2">
        <h1 className="text-lg font-bold letter-28 text-black">PROFILE</h1>
      </div>
      <div className="bg-white rounded-md p-4 flex flex-nowrap">
        <div className="flex-1 animate-all-500 p-4">
          <span className="text-black font-bold text-lg">User information</span>
          <div className='mt-3 p-4 flex items-center'>
            <div className="rounded-md w-[400px] h-[400px]">
              <div className="wrapper">
                <div className="avatar-wrapper">
                  <Avatar
                    className='flex-shrink-0 border'
                    src={currentUser.info?.avatarUrl}
                  />
                </div>
                <div className='divider'/>
                <div className="wrapper-info">
                  <div className="wrapper-content">
                    <div className="profile-item">
                      <span className="profile-item-label">First name</span>
                      <span className="profile-item-data">{currentUser.info?.firstName || 'Chris'}</span>
                    </div>
                    <div className="profile-item">
                      <span className="profile-item-label">Last name</span>
                      <span className="profile-item-data">{currentUser.info?.lastName || 'Mukasa'}</span>
                    </div>
                  </div>
                  <div className="profile-item mt-5">
                    <span className="profile-item-label">Email</span>
                    <span className="profile-item-data">{currentUser.email || 'mukasa.chris7@gmail.com'}</span>
                  </div>
                  <div className="wrapper-content mt-5">
                    <div className="profile-item">
                      <span className="profile-item-label">Role</span>
                      <span className="profile-item-data">{currentUser.role || 'Admin'}</span>
                    </div>
                    <Button type='primary'>Update</Button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </Scaffold>
  )
}

export default Profile;