import { useCallback, useMemo } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { ErrorsState, TreeDataState } from 'app/bloc/atoms';
import Api from 'app/Services';
import ENDPOINTS from 'app/Services/endpoints';
import { ITree } from 'app/typings';
import {
  allProductsNode,
  toTreeModel,
} from 'app/utils/helpers/elementizers';

const useTreeData = (): [ITree[], () => Promise<void>] => {
  const [{ treeData }, setTreeDataState] = useRecoilState(TreeDataState);
  const setErrorState = useSetRecoilState(ErrorsState);

  const regions = useMemo(() => {
    const regionsData = {} as ITree;
    treeData[0]?.children?.forEach((line) => {
      line.children?.forEach((region) => {
        if (!regionsData[region.name]) {
          regionsData[region.name] = {
            ...region,
            children: [],
          };
        }
      });
    });
    return Object.values(regionsData);
  }, [treeData]);

  const fetchTreeData = useCallback(async () => {
    if (!treeData.length) {
      setTreeDataState((state) => ({
        ...state,
        loading: true,
      }));

      const { data, status, error, message } = await Api.get(
        ENDPOINTS.TREE_NAVIGATOR,
      );

      if (error && message) {
        setErrorState((errors) => ({
          ...errors,
          aggregationError: { error, message },
        }));

        setTreeDataState((state) => ({
          ...state,
          loading: false,
        }));
        return;
      }

      if (status === 404 || status === 500) {
        setTreeDataState((state) => ({
          ...state,
          loading: false,
        }));

        setErrorState((errors) => ({
          ...errors,
          isServerDown: true,
        }));
        return;
      }

      if (data && data.lines) {
        const linesTree = data.lines.map(toTreeModel);
        Object.assign(allProductsNode, { children: linesTree });

        setTreeDataState((state) => ({
          ...state,
          treeData: [allProductsNode],
          treeDataResponse: data.lines,
          loading: false,
          hasLoaded: false,
        }));

        setErrorState({
          aggregationError: {
            error: null,
            message: null,
          },
          isServerDown: false,
        });
      }
    }
  }, [setErrorState, setTreeDataState, treeData]);

  return [regions, fetchTreeData];
};

export default useTreeData;
