import React, {Key, useEffect, useRef, useState } from 'react';
import { Layout, PageHeader, Result } from "antd";
import { Link } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { GiHamburgerMenu } from "react-icons/gi";
import LocalStorage from 'app/utils/helpers/LocalStorage';
import { TAny, TSupportedKeys } from 'app/typings';
import {
  CollapseSider,
  ErrorsState,
  expandedKeysState,
} from 'app/bloc/atoms';
import TreeNavigator from '../TreeNavigator';
import TopNavbar from './TopNavbar';

const { Sider } = Layout;

interface Props {
  children: React.ReactNode | React.ReactNode[];
  selectedKeys?: Key[];
  expandedTreeKey?: TSupportedKeys;
  sideBar?: React.ReactNode;
  hideSideBarInMedium?: boolean;
  onSelect?: (keys: Key[], info: TAny) => void;
}

const Scaffold = ({
  children,
  selectedKeys,
  expandedTreeKey,
  sideBar,
  hideSideBarInMedium,
  onSelect,
}: Props) => {
  const siderRef = useRef<HTMLDivElement>(null);
  // const { treeData } = useRecoilValue(TreeDataState);
  const errorsState = useRecoilValue(ErrorsState);
  const [expandedKeys, setExpandedKeys] = useRecoilState(expandedKeysState);
  const collapsed = useRecoilValue(CollapseSider);

  const [showTreeNavigator, setShowTreeNavigator] = useState(true);

  // const defaultSelectedKeys = getSelectedKeys(treeData);

  const siderClassName = showTreeNavigator ? 'hidden lg:block' : 'hidden';

  const onToggleNavigator = () => {
    siderRef.current?.classList.toggle('hidden');
  };

  const onExpand = (keys) => {
    if (expandedTreeKey) {
      LocalStorage.setExpandedTreeKey(JSON.stringify(keys))
      setExpandedKeys((currVal) => ({
        ...currVal,
        [expandedTreeKey]: keys,
      }));
    }
  };

  useEffect(() => {
    const hasError =
      !!errorsState.aggregationError?.error &&
      !!errorsState.aggregationError?.message;

    setShowTreeNavigator(!hasError);
  }, [errorsState.aggregationError, setShowTreeNavigator]);

  if (errorsState.isServerDown) {
    return (
      <Result
        status="500"
        title="Ongoing maintenance"
        subTitle={
          <div>
            <p>
              We are having technical difficulties and working on
              fixing the issue.
            </p>
            <p>We are sorry for inconvenience.</p>
          </div>
        }
        extra={
          <Link
            type="primary"
            to="/"
            onClick={() => {
              LocalStorage.clear();
            }}
          >
            Back Home
          </Link>
        }
      />
    );
  }

  return (
    <Layout style={{ height: '100vh', overflowX: 'auto' }}>
      <PageHeader
        className="site-page-header-responsive border-b p-0 pl-2 md:pr-4"
        backIcon={false}
        title={
          <span className='flex'>
              {hideSideBarInMedium && (
                <div
                  key="navigator"
                  className='lg:hidden pr-2'
                  tabIndex={0}
                  role="button"
                  onKeyDown={() => null}
                  onClick={onToggleNavigator}
                >
                  <GiHamburgerMenu size="22.4px" />
                </div>
              )}
            <span>BARBADOS</span>
          </span>
        }
        extra={ <TopNavbar/> }
      />
      <Layout>
        <Sider
          ref={siderRef}
          collapsed={collapsed}
          className={`${ hideSideBarInMedium ? siderClassName : '' } navigator-panel`}
        >
          {sideBar || (
            <TreeNavigator
              onSelect={onSelect}
              onExpand={onExpand}
              selectedKeys={selectedKeys}
              expandedKeys={(expandedTreeKey && expandedKeys[expandedTreeKey]) || []}
            />
          )}
        </Sider>

        <Layout>
          <div className="p-2 md:p-4 pb-16 overflow-y-auto w-full">
            {(!errorsState.aggregationError?.error && !errorsState.aggregationError?.message && children) || null}

            {(errorsState.aggregationError?.error && errorsState.aggregationError?.message && (
              <Result
                status={404}
                title={errorsState.aggregationError?.error}
                subTitle={errorsState.aggregationError?.message}
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                extra={
                  <Link type="primary" to='/' onClick={() => { LocalStorage.logOut() }}>
                    Reload
                  </Link>
                }
              />
            )) || null }
          </div>
        </Layout>
      </Layout>
    </Layout>
  );
};

Scaffold.defaultProps = {
  selectedKeys: [],
  onSelect: undefined,
  sideBar: null,
  hideSideBarInMedium: true,
  expandedTreeKey: 'reportKeys',
};

export default Scaffold;
