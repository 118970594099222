import React from 'react';

const DropdownArrow = ({ className = '' }) => (
  <svg
    width="8"
    height="6"
    viewBox="0 0 8 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M1.13504 0C0.287204 0 -0.175952 0.988857 0.36682 1.64018L3.23178 5.07814C3.63157 5.55789 4.36843 5.55789 4.76822 5.07813L7.63318 1.64018C8.17595 0.988856 7.7128 0 6.86496 0H1.13504Z"
      fill="#76808F"
    />
  </svg>
);

export default DropdownArrow;
