import React, { FC } from 'react';

interface Props {
  color?: string;
  height?: number;
  width?: number;
}

const SwitcherIcon: FC<Props> = ({ color, height, width }: Props) => {
  return (
    <div
      style={{
        height: `${height}px`,
        width: `${width}px`,
        backgroundColor: color,
        borderRadius: '2px',
      }}
    />
  );
};

SwitcherIcon.defaultProps = {
  color: '#004688',
  height: 15,
  width: 15,
};

export default SwitcherIcon;
