import React from 'react';
import { Row, Table } from 'antd';
import moment from 'moment';
import { TAny } from 'app/typings';
import { MONTHLY_DISPLAY_FORMAT } from 'app/utils/helpers/constants';

interface Props {
  region: string;
  data: {
    [key: string]:
      | string
      | number
      | undefined
      | { [key: string]: string | number | undefined };
  }[];
  currentYear: string | moment.Moment;
  previousMonths: string[];
  nextMonths: string[];
  loading: boolean;
}

const render = (text) => {
  if (!text) return null;
  return text;
};

const onCell = (record) => {
  if (
    (record.key === 'current' && !record?.lastColumn) ||
    (record.key === 'last' && !record?.lastColumn)
  ) {
    return { className: 'font-bold border-b-none' };
  }
  if (record.groupedCells) {
    return { className: 'grouped-cells' };
  }
  if (record?.lastColumn) {
    return { className: 'last-cell' };
  }
  return { className: 'border-b-none' };
};

const onHeaderCell = (
  className = 'child-header border-l-2 border-branding-blue font-bold',
) => ({
  className,
});

const renderCellClass = (record, cls = '') => {
  const cellObj = onCell(record);
  cellObj.className = `${cellObj.className} ${cls}`;

  return cellObj;
};

const populateColumns = (
  months,
  lastColumns = { onCell: renderCellClass },
) => {
  return months?.map((month, index) => {
    let columnRow = {
      title: moment(month).format('MMM-YY'),
      dataIndex: month,
      className: 'small',
      onHeaderCell: () =>
        onHeaderCell('child-header border-branding-blue font-bold'),
      onCell: renderCellClass,
      render,
    } as TAny;

    if (index === 0) {
      columnRow.onHeaderCell = () => onHeaderCell();
      columnRow.onCell = (record) =>
        renderCellClass(record, 'border-l-2 border-branding-blue');
    }

    if (index === months.length - 1) {
      columnRow = {
        ...columnRow,
        ...lastColumns,
      };
    }
    return columnRow;
  });
};

const columns = [
  {
    className: 'small',
    onHeaderCell: () => ({
      className: 'bg-transparent',
    }),
    children: [
      {
        title: 'EU',
        dataIndex: 'field',
        className: 'small',
        onHeaderCell: () => onHeaderCell(),
        onCell: (record) =>
          renderCellClass(record, 'border-l-2 border-branding-blue'),
      },
    ],
  },
  {
    className: 'small border-b-2 border-branding-blue',
    title: 'Actuals',
    onHeaderCell: () =>
      onHeaderCell('child-header border-l-2 border-branding-blue'),
    children: [],
  },
  {
    title: 'Projection',
    className: 'small border-t-2 border-l-2 border-branding-blue',
    dataIndex: 'actuals',
    children: [
      {
        title: 'Jan 22',
        dataIndex: 'forecast_4',
        className: 'small',
        onHeaderCell: () => onHeaderCell(),
        onCell: (record) =>
          renderCellClass(record, 'border-l-2 border-branding-blue'),
        render,
      },
    ],
  },
  {
    title: 'Forecast',
    className:
      'small border-b-2 border-x-2 border-t-2 border-branding-blue',
    dataIndex: 'forecast',
    children: [],
  },
  {
    onHeaderCell: () => ({
      className: 'bg-transparent',
    }),
    children: [
      {
        title: 'FY 2022',
        dataIndex: 'fiscalYear',
        className:
          'small child-header border-r-2 border-branding-blue',
        render: (text) => {
          if (!text) return null;
          if (typeof text === 'string') {
            return <div className="font-bold">{text}</div>;
          }

          const values = Object.keys(text);

          return (
            <>
              {values.map((key) => {
                return (
                  <Row justify="space-between" key={key}>
                    <div className="font-bold">{key}</div>
                    <div>{text[key]}</div>
                  </Row>
                );
              })}
            </>
          );
        },
        onCell: (record) => {
          if (record.lastColumn) return { className: 'last-cell' };
          return { className: 'border-b-none' };
        },
        onHeaderCell: () => ({ className: 'font-bold' }),
      },
    ],
  },
];

const ProjectsReportTable = ({
  data,
  region,
  currentYear,
  previousMonths,
  nextMonths,
  loading,
}: Props) => {
  columns[0].children[0].title = region;

  // CURRENT YEAR
  columns[2].children[0].title = moment(currentYear).format('MMM-YY');
  columns[2].children[0].dataIndex = moment(currentYear).format(MONTHLY_DISPLAY_FORMAT);

  // FULL YEAR
  columns[columns.length - 1].children[0].title = `FY ${moment(currentYear).format('YYYY')}`;

  // PREVIOUS MONTHS
  columns[1].children = populateColumns(previousMonths);

  // NEXT MONTHS
  const lastColumns = {
    onHeaderCell: () =>
      onHeaderCell(
        'child-header border-r-2 border-branding-blue font-bold',
      ),
    onCell: (record) =>
      renderCellClass(record, 'border-r-2 border-branding-blue'),
  };
  columns[3].children = populateColumns(nextMonths, lastColumns);

  return (
    <Table
      className="borderless-table"
      columns={columns}
      dataSource={data}
      pagination={false}
      bordered={false}
      loading={loading}
    />
  );
};

export default ProjectsReportTable;
