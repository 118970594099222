import { AxiosRequestConfig, AxiosResponse } from 'axios';
import Axios from 'app/Services/config';
import LocalStorage from 'app/utils/helpers/LocalStorage';
import { TAny } from "../typings";

class Api {
  static handleError = (err: TAny): {
    error: string | TAny;
    data?: null;
    message?: null;
    status?: string;
  } => {
    if (!err.response) {
      return {
        error: 'It seems that you are offline. Try to check your network cable.',
      };
    }

    const { data, status } = err.response;

    if (Array.isArray(data) && typeof data[0] === 'string') {
      return { error: data[0] };
    }

    const { error, message } = data;

    if (error && message) {
      return { error, message };
    }

    if ((message && status !== 201) || (message && status !== 200)) {
      return { error: message, status };
    }

    if (typeof error !== 'string') {
      return { error: 'Unknown error', status };
    }

    return { error, status };
  };

  static resolveResponse = (response: AxiosResponse) => {
    const { data, error, message, status } = response.data;

    return { data: data || response.data, message, error, status };
  };

  static bindToken = () => {
    const token = LocalStorage.getAccessToken();
    Axios.defaults.headers.common.Authorization = token ? `Bearer ${token}` : '';
  };

  static async get(url: string, params = {}) {
    this.bindToken();

    return Axios.get(url, { params })
      .then(this.resolveResponse)
      .catch(this.handleError);
  }

  static async post(
    url: string,
    data: TAny,
    config?: AxiosRequestConfig,
  ) {
    this.bindToken();

    return Axios.post(url, data, config)
      .then(this.resolveResponse)
      .catch(this.handleError);
  }

  /* eslint-comments/disable-enable-pair */
  static async put(url: string, data: TAny) {
    this.bindToken();

    return Axios.put(url, data)
      .then(this.resolveResponse)
      .catch(this.handleError);
  }

  static async delete(url: string, data?: TAny) {
    this.bindToken();

    return Axios.delete(url, data)
      .then(this.resolveResponse)
      .catch(this.handleError);
  }
}

export default Api;
