import React from "react";
import { Button, Dropdown, Menu } from 'antd';
import { HiOutlineDownload } from 'react-icons/hi';
import { useRecoilValue } from "recoil";
import DropdownArrow from '../_drawables/DropdownArrow';
import PdfFvaGenerator from "../../utils/sample/pdf_fva_generator";
import { HorizonRangeDataState, HorizonRangeState, LagSelectionState } from "../../bloc/atoms";
import LocalStorage from "../../utils/helpers/LocalStorage";
import { IResponseFvaData } from "../../typings";

type Props = {
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  data? : IResponseFvaData | undefined;
  onExportToExcel?: (() => void) | null;
};

const ExportReportButton = ({
  disabled,
  loading,
  className,
  data,
  onExportToExcel,
}: Props) => {

  const horizon = useRecoilValue(HorizonRangeState);
  const horizonRange = useRecoilValue(HorizonRangeDataState);
  const lagSelection = useRecoilValue(LagSelectionState)

  const spaceRemover = (data?:string|null) => {
    const lower = data?.toLowerCase();
    const upper = data?.toUpperCase();

    let res = "";
    for(let i = 0; i < lower!.length; i+=1) {
      if(lower![i] !== upper![i] || lower![i].trim() === '')
        res += data![i];
    }
    return res;
  }

  const selectedKey = LocalStorage.getSelectedTreeKey();
  let item = '-'

  if(selectedKey !== null){
    const sheet = spaceRemover(selectedKey);
    const items = sheet.split(" ");
    item = items.slice(-1)[0]
  }

  return (
    <Dropdown
      disabled={disabled}
      trigger={['click']}
      overlay={
        <Menu className="border rounded-md">
          {onExportToExcel && (
            <Menu.Item key="excel" onClick={onExportToExcel}>
              Export as Excel
            </Menu.Item>
          )}
          <Menu.Divider />
          <Menu.Item
            key="pdf"
            onClick={() => PdfFvaGenerator(
              horizon,
              horizonRange[0],
              horizonRange[1],
              lagSelection,
              item,
              data
            )}>
            Export as PDF
          </Menu.Item>
        </Menu>
      }
    >
      <Button
        className={`flex items-center justify-center rounded-sm p-3 border-w-2 border-gray-300 ${className}`}
        disabled={disabled}
        loading={loading}
        style={{ display: 'flex' }}
      >
        <HiOutlineDownload />
        <span className="mx-2">Export</span>
        <DropdownArrow />
      </Button>
    </Dropdown>
  );
};

ExportReportButton.defaultProps = {
  loading: false,
  disabled: false,
  className: '',
  data: undefined,
  onExportToExcel: null,
};

export default ExportReportButton;
