import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  Form,
  Input,
  message,
  Progress,
  Select,
  Upload,
} from 'antd';
import { HiPlus, HiOutlinePencil } from 'react-icons/hi';
import { useRecoilState } from 'recoil';
import { UploadFile } from 'antd/lib/upload/interface';
import axios from 'axios';
import ModalWrapper from 'app/__portions/ModalWrapper';
import { TAny } from 'app/typings';
import { staleAndWait, toUser } from 'app/utils/helpers';
import { UsersState } from 'app/bloc/atoms';

import './style.css';
import { USER_ROLES } from 'app/utils/helpers/constants';
import DropdownArrow from 'app/__portions/_drawables/DropdownArrow';
import Api from 'app/Services';
import ENDPOINTS from 'app/Services/endpoints';

const CreateUser = () => {
  const [form] = Form.useForm();
  const [{ isCreate, isEdit, currentUser, users }, setCreateModal] =
    useRecoilState(UsersState);

  const [fileList, setFileList] = useState<UploadFile<TAny>[]>([]);
  const [preview, setPreview] = useState<string | undefined>();

  const [performing, setPerforming] = useState(false);
  const [upload, setUpload] = useState({
    uploading: false,
    progress: 0,
  });

  const initialValues = isEdit
    ? {
        firstName: currentUser?.info?.firstName,
        lastName: currentUser?.info?.lastName,
        role: currentUser?.role,
        email: currentUser.email,
      }
    : {};

  const onClose = () => {
    setCreateModal((currVal) => ({
      ...currVal,
      isCreate: false,
      isEdit: false,
    }));
  };

  useEffect(() => {
    if (currentUser?.info?.avatarUrl && isEdit) {
      setPreview(currentUser.info.avatarUrl);
    }
  }, [currentUser?.info?.avatarUrl, isEdit]);

  useEffect(() => {
    if (currentUser && Object.keys(currentUser) && isCreate) {
      form?.resetFields();
      setPreview(undefined);
    }

    if (isEdit) {
      form.setFieldsValue({
        first_name: currentUser?.info?.firstName,
        last_name: currentUser?.info?.lastName,
        role: currentUser?.role,
        email: currentUser.email,
      });
    }
  }, [currentUser, form, isCreate, isEdit]);

  const getUploadedFile = async (): Promise<string | null> => {
    if (!fileList.length) return null;

    try {
      const formData = new FormData();
      formData.set('file', fileList[0] as unknown as File);

      const config = {
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percent = Math.floor((loaded * 100) / total);

          if (percent <= 100) {
            const progress = Math.floor((percent / 100) * 180);
            setUpload({ ...upload, progress });
          }
        },
      };

      setUpload({ ...upload, uploading: true });

      const response = await axios.post(
        process.env.REACT_FILE_SERVICES as string,
        formData,
        config,
      );

      if (response) {
        const { data } = response;

        if (data) {
          const { savedFiles } = data;
          setUpload({ ...upload, uploading: false });

          return savedFiles.file;
        }

        setUpload({ progress: 0, uploading: false });
        message.warning(
          'Something went wrong while uploading the file',
        );
      }
    } catch (error) {
      setUpload({ progress: 0, uploading: false });
      return null;
    }
    return null;
  };

  const onEditUser = async (values) => {
    const profileImage = await getUploadedFile();

    if (profileImage) {
      values.profile_image = profileImage;
    }

    if (values.role.length > 2) {
      values.role = USER_ROLES[values.role].value;
    }

    const { data, error } = await Api.put(
      `${ENDPOINTS.USERS}/${currentUser.key}`,
      values,
    );

    if (error) {
      if (
        Object.keys(error).length &&
        Object.getPrototypeOf(error) === Object.prototype
      ) {
        Object.keys(error).forEach((err) => {
          message.error(error[err][0]);
        });
        return;
      }
      message.error(
        'Failed to update user information. Please try again',
      );
      setPerforming(false);
      return;
    }

    const allUsers = [...users];

    const userIndex = allUsers.findIndex(
      (userData) => userData.key === currentUser.key,
    );

    if (userIndex > -1) {
      allUsers[userIndex] = toUser(data);

      setCreateModal((state) => ({ ...state, users: allUsers }));

      await staleAndWait();

      message.success('User information successfully updated!');

      setPerforming(false);
      form.resetFields();

      onClose();
      return;
    }
    message.error(
      'Failed to update user information. Please try again',
    );
  };

  const onCreateUser = async (values) => {
    const profileImage = await getUploadedFile();

    if (profileImage) {
      values.profile_image = profileImage;
    }

    const { data, error } = await Api.post(ENDPOINTS.USERS, values);

    if (error) {
      if (
        Object.keys(error).length &&
        Object.getPrototypeOf(error) === Object.prototype
      ) {
        Object.keys(error).forEach((err) => {
          message.error(error[err][0]);
        });
        return;
      }
      message.error('Failed to add the user. Please try again');
      setPerforming(false);
      return;
    }

    const newUser = toUser(data);
    const allUsers = [...users];

    allUsers.push(newUser);
    setCreateModal((state) => ({ ...state, users: allUsers }));

    await staleAndWait();

    message.success('User was successfully added!');

    setPerforming(false);
    form.resetFields();

    onClose();
  };

  const onFinish = async (values) => {
    setPerforming(true);

    if (isEdit) {
      await onEditUser(values);
    }

    if (isCreate) {
      await onCreateUser(values);
    }
  };

  const uploadProps = {
    fileList,
    showUploadList: false,
    accept: ['image/png', 'image/jpeg', 'image/webp'].join(),
    beforeUpload: (file) => {
      if (file.size > 1024 * 1024) {
        message.warning(
          'Image is too large, please upload an image of maximum 1MB',
        );
        return false;
      }
      setFileList([file]);

      setPreview(URL.createObjectURL(file));
      return false;
    },
  };

  return (
    <ModalWrapper
      width={440}
      visible={isCreate || isEdit}
      onCancel={onClose}
    >
      <h3>{isCreate ? 'Add New User' : 'Edit Information'}</h3>
      <Form
        form={form}
        layout="vertical"
        requiredMark
        initialValues={initialValues}
        onFinish={onFinish}
      >
        <div className="flex justify-center mb-3 py-2">
          {!preview && (
            <Upload listType="picture-card" {...uploadProps}>
              <HiPlus className="text-black-50" size="16px" />
            </Upload>
          )}

          {preview && (
            <div className="border rounded-md p-1 relative">
              <Upload
                className="change-profile-button border"
                listType="picture"
                {...uploadProps}
              >
                <HiOutlinePencil className="text-black-50" />
              </Upload>
              <Avatar
                src={preview}
                size={100}
                shape="square"
                className="rounded-md"
              />
            </div>
          )}
        </div>

        <div className="flex justify-between">
          <Form.Item
            label="First Name"
            required
            tooltip="First Name is required"
            name="first_name"
            className="w-full"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please input user's First name!",
              },
            ]}
            style={{ marginRight: '0.75rem' }}
          >
            <Input
              placeholder="First Name"
              className="border-w-2 rounded-sm p-1"
            />
          </Form.Item>
          <Form.Item
            label="Last Name"
            required
            tooltip="Last Name is required"
            name="last_name"
            className="w-full"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please input user's Last name!",
              },
            ]}
          >
            <Input
              placeholder="Last Name"
              className="border-w-2 rounded-sm p-1"
            />
          </Form.Item>
        </div>
        <Form.Item
          label="Password"
          required={isCreate}
          tooltip={isCreate ? 'Password is required' : null}
          name="password"
          className="w-full"
          hasFeedback
          rules={[
            {
              required: isCreate,
              message: "Please input user's Password!",
            },
          ]}
        >
          <Input.Password
            autoComplete="off"
            placeholder="Password"
            className="border-w-2 rounded-sm p-1"
          />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          required
          tooltip="Provide a valid email address"
          hasFeedback
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
              message: "Please input user's E-mail!",
            },
          ]}
        >
          <Input
            type="email"
            placeholder="Email"
            className="border-w-2 rounded-sm p-1"
          />
        </Form.Item>

        <Form.Item
          label="Role"
          name="role"
          required
          hasFeedback
          rules={[
            { required: true, message: "Please select user's role!" },
          ]}
        >
          <Select
            placeholder="Role"
            className="border-w-2 select-options rounded-sm"
            options={Object.values(USER_ROLES)}
            suffixIcon={DropdownArrow}
          />
        </Form.Item>

        {upload.uploading && <Progress percent={upload.progress} />}

        <Button
          htmlType="submit"
          type="primary"
          className="rounded-md w-full btn-height px-3 mr-2"
          disabled={performing}
          loading={performing}
        >
          {isCreate ? 'Add User' : 'Save Changes'}
        </Button>
      </Form>
    </ModalWrapper>
  );
};

export default CreateUser;
