import axios from 'axios';
import keys from 'app/utils/configs/keys';
import LocalStorage from 'app/utils/helpers/LocalStorage';
import isExpired from 'app/utils/helpers/isExpired';
import ENDPOINTS from './endpoints';

const baseURL = keys.DEFAULT_API;
const token = LocalStorage.getAccessToken();

const Axios = axios.create({ baseURL });

Axios.defaults.baseURL = baseURL as string;
Axios.defaults.headers.post.Accept = 'application/json';
Axios.defaults.headers.common.Authorization = token
  ? `Bearer ${token}`
  : '';

const refreshToken = async () => {
  const refreshToken = LocalStorage.getRefreshToken();
  if (refreshToken) {
    const response = await Axios.post(ENDPOINTS.REFRESH, {
      refresh: refreshToken,
    });
    if (response.status === 200) {
      return Promise.resolve(response);
    }
  }
  return Promise.reject();
};

Axios.interceptors.request.use(
  async (config) => {
    if (
      config.url !== ENDPOINTS.LOGIN &&
      config.url !== ENDPOINTS.REFRESH &&
      config.url !== ENDPOINTS.LOG_OUT &&
      isExpired()
    ) {
      try {
        const res = await refreshToken();
        const { data } = res;
        const token = data.access;
        LocalStorage.setAccessToken(token);
        LocalStorage.setRefreshToken(data.refresh);
        Axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        // @ts-ignore
        config.headers = { ...config.headers, Authorization: `Bearer ${token}` };
      } catch (error) {
        window.location.href = '/';
        LocalStorage.removeAccessToken();
        LocalStorage.removeRefreshToken();
        return Promise.reject();
      }
    }
    return config;
  },
  (error) => {
    window.location.href = '/';
    LocalStorage.removeAccessToken();
    LocalStorage.removeRefreshToken();
    return Promise.reject(error);
  },
);

export default Axios;
