import moment from 'moment';
import { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { useRecoilState, useRecoilValue } from 'recoil';
import { CapacityState } from 'app/bloc/atoms';
import { TAny } from 'app/typings';
import Tabs from 'app/__portions/Tabs';
import { UNIT_MEASURES } from 'app/utils/helpers/constants';

const options = {
  type: 'line',
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: 'right' as const,
    },
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const chartTypes = ['Weekly', 'Monthly'];

const CapacityChart = () => {
  const { capacities, unit } = useRecoilValue(CapacityState);

  const { weeks, days, months } = capacities;

  const [{ currentChart }, setCurrentType] =
    useRecoilState(CapacityState);

  const barData = useMemo(() => {
    if (currentChart === 1) {
      return {
        labels: months.map((month) => month.title),
        datasets: [
          {
            label: 'Monthly',
            backgroundColor: '#004688',
            data: months.map((month) => month.load),
          },
          {
            label: 'Monthly Total',
            type: 'line' as TAny,
            fill: false,
            pointRadius: 0,
            backgroundColor: '#696969',
            borderColor: '#696969',
            data: months.map((month) => month.load),
          },
        ],
      };
    }

    return {
      labels: weeks.map((week) => {
        const lastDay = days
          .filter((day) => day.week === week.title)
          .slice(-1)[0];

        return moment(`${lastDay.month}-${lastDay.title}`).format(
          'DD-MMM-YY',
        );
      }),
      datasets: [
        {
          label: 'Weekly',
          backgroundColor: '#004688',
          data: weeks.map((week) => week.load),
        },
        {
          label: 'Weekly Total',
          type: 'line' as TAny,
          fill: false,
          pointRadius: 0,
          backgroundColor: '#696969',
          borderColor: '#696969',
          data: weeks.map((week) => week.load),
        },
      ],
    };
  }, [currentChart, weeks, months, days]);

  const summary = useMemo(() => {
    if (!months.length || !weeks.length || !days.length) return null;
    if (currentChart === 1) {
      const firstDay = days[0];
      const lastDay = days[days.length - 1];

      const monthly = months.reduce(
        (sum, month) => sum + month.load,
        0,
      );
      return `${monthly.toLocaleString()} ${
        UNIT_MEASURES[unit]
      } - Total Montly Capacity loaded from ${moment(
        `${firstDay.day} ${firstDay.month}-${firstDay.title}`,
      ).format('ddd, DD MMM YYYY')} to ${moment(
        `${lastDay.day} ${lastDay.month}-${lastDay.title}`,
      ).format('ddd, DD MMM YYYY')}`;
    }
    const weekly = weeks.reduce((sum, week) => sum + week.load, 0);
    return `${weekly.toLocaleString()} ${
      UNIT_MEASURES[unit]
    } - Total Weekly Capacity loaded from ${weeks[0].title} to ${
      weeks[weeks.length - 1].title
    }`;
  }, [currentChart, days, months, unit, weeks]);

  return (
    <div className="my-5">
      <div className="ant-row ant-row-space-between items-center my-2 mt-5">
        <div className="text-xs text-black-50">{summary}</div>
        <Tabs
          tabs={chartTypes}
          onTabChange={(index) =>
            setCurrentType((val) => ({
              ...val,
              currentChart: index,
            }))
          }
        />
      </div>
      <Bar data={barData} options={options} />
    </div>
  );
};

export default CapacityChart;
