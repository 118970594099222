import React, { FC } from 'react';
import { Table } from 'antd';
import moment from 'moment';

interface Props {
  data: Record<string, string | number>[];
  region: string;
  currentYear?: string;
  loading: boolean;
}

const renderBudgetCell = (record, index) => {
  if (index === 1) {
    return { className: 'budget-cell' };
  }
  if (record.key.includes('current') || record.key.includes('last')) {
    return { className: 'font-bold border-b-none' };
  }
  if (record.groupedCells) {
    return { className: 'grouped-cells' };
  }
  if (record && record.lastColumn === true) {
    return { className: 'last-cell' };
  }
  return { className: 'border-b-none' };
};

const renderHeaderCell = (prefixCls = 'font-bold') => ({
  className: `child-header ${prefixCls}`,
});

const renderCellClass = (
  record,
  index,
  cls = 'border-l-2 border-branding-blue',
) => {
  const cellObj = renderBudgetCell(record, index);
  cellObj.className = `${cellObj.className} ${cls}`;

  return cellObj;
};

const render = (text) => {
  if (!text) return null;
  return text;
};

const columns = [
  {
    className: 'small',
    width: 100,
    onHeaderCell: () => ({
      className: 'bg-transparent',
    }),
    children: [
      {
        title: 'EU',
        dataIndex: 'field',
        width: 100,
        className: 'small border-l-2 border-branding-blue',
        onHeaderCell: () => ({
          className: 'current-region child-header font-bold',
        }),
        onCell: renderBudgetCell,
      },
    ],
  },
  {
    className:
      'small child-header border-l-2 border-r-2 border-branding-blue',
    title: 'Current Fiscal Year (CFY)',
    dataIndex: 'currentFiscalYear',
    width: 100,
    children: [
      {
        title: 'Q1',
        width: 40,
        dataIndex: 'cfyQ1',
        className: 'small border-l-2 border-branding-blue',
        onCell: renderBudgetCell,
        onHeaderCell: () => renderHeaderCell(),
        render,
      },
      {
        title: 'Q2',
        width: 40,
        dataIndex: 'cfyQ2',
        className: 'small',
        onCell: renderBudgetCell,
        onHeaderCell: () => renderHeaderCell(),
        render,
      },
      {
        title: 'Q3',
        width: 40,
        dataIndex: 'cfyQ3',
        className: 'small',
        onCell: renderBudgetCell,
        onHeaderCell: () => renderHeaderCell(),
        render,
      },
      {
        title: 'Q4',
        width: 40,
        dataIndex: 'cfyQ4',
        className: 'small',
        onCell: renderBudgetCell,
        onHeaderCell: () => renderHeaderCell(),
        render,
      },
      {
        title: 'FY 2022',
        width: 40,
        dataIndex: 'cfyFY',
        className: 'small',
        onCell: (record, index) =>
          renderCellClass(
            record,
            index,
            'border-x-2 border-branding-blue',
          ),
        onHeaderCell: () =>
          renderHeaderCell(
            'border-x-2 border-branding-blue font-bold',
          ),
        render,
      },
    ],
  },
  {
    title: 'Next Fiscal Year (NFY)',
    className: 'small child-header border-r-2 border-branding-blue',
    dataIndex: 'nextFiscalYear',
    width: 100,
    children: [
      {
        title: 'Q1',
        width: 40,
        dataIndex: 'nfyQ1',
        className: 'small',
        onCell: renderBudgetCell,
        onHeaderCell: () => renderHeaderCell(),
        render,
      },
      {
        title: 'Q2',
        width: 40,
        dataIndex: 'nfyQ2',
        className: 'small',
        onCell: renderBudgetCell,
        onHeaderCell: () => renderHeaderCell(),
        render,
      },
      {
        title: 'Q3',
        width: 40,
        dataIndex: 'nfyQ3',
        className: 'small',
        onCell: renderBudgetCell,
        onHeaderCell: () => renderHeaderCell(),
        render,
      },
      {
        title: 'Q4',
        width: 40,
        dataIndex: 'nfyQ4',
        className: 'small',
        onCell: renderBudgetCell,
        onHeaderCell: () => renderHeaderCell(),
        render,
      },
      {
        title: 'FY 2022',
        width: 40,
        dataIndex: 'nfyFY',
        className: 'small border-x-2 border-branding-blue',
        onCell: renderCellClass,
        onHeaderCell: () => renderHeaderCell(),
        render,
      },
    ],
  },
];

const FiscalReportTable: FC<Props> = ({
  data,
  region,
  currentYear,
  loading,
}: Props) => {
  columns[0].children[0].title = region;

  // UPDATE TITLE OF CURRENT FISCAL YEAR
  columns[1].children[4].title = `FY ${moment(currentYear).format(
    'YYYY',
  )}`;

  // // UPDATE TITLE OF NEXT FISCAL YEAR
  columns[2].children[4].title = `FY ${moment(currentYear)
    .add(1, 'years')
    .format('YYYY')}`;

  return (
    <Table
      className="borderless-table"
      columns={columns}
      dataSource={data}
      pagination={false}
      bordered={false}
      loading={loading}
    />
  );
};

FiscalReportTable.defaultProps = {
  currentYear: moment().format('YYYY'),
};

export default FiscalReportTable;
