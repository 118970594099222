export default {
  HOME_PATH: '/',
  REPORT_PATH: '/report',
  REPORT_ANALYTICS: '/analytics',
  REPORT_ANALYTICS_SUB: '/analytics/:region',
  DEMAND_PLANNING: '/planning',
  FORECAST_VALUE_ADDED: '/fva',
  USERS_ACTIVITY: '/users',
  CAPACITY_DEMAND: '/capacity',
  SETTINGS: '/settings',
  SETTINGS_USERS: '/settings/users',
  SETTINGS_PROFILE: '/settings/profile',
  SETTINGS_NOTIFICATION: '/settings/notifications',
};
