import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  AnalyticsState,
  selectedKeysState,
  SelectedNode,
  TreeDataState,
} from 'app/bloc/atoms';
import Api from 'app/Services';
import ENDPOINTS from 'app/Services/endpoints';
import { IDataset, IGraphData, TAny } from 'app/typings';
import {
  aggregateQuarters,
  getFetchDataTreePayload,
  getDaysBetweenDates,
  getSelectedNode,
  sortByDateAfter,
  sumArrayObjectsValues,
} from 'app/utils/helpers';
import { graphColors } from 'app/utils/helpers/colors';
import {
  DAILY_DATE_FORMAT,
  DAILY_DISPLAY_FORMAT,
} from 'app/utils/helpers/constants';

const useAnalytics = () => {
  const [
    {
      fiscalData,
      hasLoaded,
      currentYear,
      graphAnalytics,
      projectionData,
    },
    setAnalyticsData,
  ] = useRecoilState(AnalyticsState);

  const { treeData, treeDataResponse } =
    useRecoilValue(TreeDataState);
  const [{ analyticsKeys }, setSelectedKeys] =
    useRecoilState(selectedKeysState);

  const [loading, setLoading] = useState(false);
  const [{ analyticsKeys: selectedNode }, setSelectedNode] =
    useRecoilState(SelectedNode);

  const actualsMonths = getDaysBetweenDates(
    moment(currentYear).subtract(3, 'months'),
    moment(currentYear),
  ).filter((actual) => !moment(actual).isSame(moment(currentYear)));

  const forecastMonths = getDaysBetweenDates(
    moment(currentYear),
    moment(currentYear).add(3, 'months'),
  ).filter((actual) => !moment(actual).isSame(moment(currentYear)));

  /**
   * This function aggregates forecast to the currently selected months.
   * Months contain 3 previous months, current month and 3 next months
   *
   * @param forecast The forecast of the currently selected year
   * @param months Combination of previous, current and next months
   * @param expand Any fields that may be added to the object
   * @returns Object
   */
  const mapForecastProjection = (forecast, months, expand = {}) => {
    const projections = {};

    Object.entries(forecast).forEach(([key, values]) => {
      if (values && typeof values === 'object') {
        projections[key] = Object.entries(values).map(
          ([valueKey, deepValues]) => {
            const groupedValues = Object.keys(deepValues)
              .filter((filterKey) => months.includes(filterKey))
              .reduce((currValue, currKey) => {
                return {
                  ...currValue,
                  [currKey]: deepValues[currKey],
                  key: `${key}-${valueKey}`,
                  [valueKey]: valueKey,
                  field: valueKey === 'Id' ? 'ID' : valueKey,
                  ...expand,
                };
              }, {});

            return groupedValues;
          },
        );

        if (projections[key]) {
          const aggregatedProjection = sumArrayObjectsValues(
            Object.values(projections[key]),
          ) as TAny;
          aggregatedProjection.key = key;
          aggregatedProjection.field =
            key === 'current' ? 'Current FCST' : 'Last Cycle';

          if (key === 'current') {
            const forecastProjValues = Object.values(
              projections[key],
            );

            projections[key] = forecastProjValues.map(
              (currentForecastGroup: TAny, index) => {
                if (index === forecastProjValues.length - 1) {
                  currentForecastGroup.groupedCells = true;
                }

                return currentForecastGroup;
              },
            );
          }

          projections[key] = [
            aggregatedProjection,
            ...projections[key],
          ];
        }
      }
    });

    return projections;
  };

  const fetchAnalytics = useCallback(
    async (lines = treeDataResponse) => {
      setLoading(true);

      if (!selectedNode.title) return;

      const year = currentYear.split('-')[0];
      const graphData = { datasets: [] } as IGraphData;

      const payload = {
        lines,
        date: moment(currentYear).format(DAILY_DATE_FORMAT),
      };

      const { data } = await Api.post(
        ENDPOINTS.FETCH_ANALYTICS,
        payload,
      );

      setLoading(false);

      if (data) {
        const { forecast, actual, budget } = data;

        const fiscalForecasts = {};
        const actualRow = aggregateQuarters(
          actual,
          year,
          'Actual LY',
        );
        const budgetRow = aggregateQuarters(budget, year, 'Budget');

        Object.keys(forecast).forEach((key) => {
          const forecastObj = {} as TAny;
          const groupedForecasts = forecast[key];

          const groupedForecastsKeys = Object.keys(groupedForecasts);
          const aggregatedForecastGroup = sumArrayObjectsValues(
            Object.values(groupedForecasts),
          );

          // GET GRAPH DATA FOR LAST CYCLE
          if (key === 'last') {
            const datasets = [...graphData.datasets];
            const lastCycle: IDataset = {
              type: 'line',
              label: 'Last Cycle',
              fill: false,
              pointRadius: 0,
              data: sortByDateAfter(
                aggregatedForecastGroup,
              ) as string[],
              backgroundColor: '#696969',
              borderColor: '#696969',
            };

            datasets.splice(0, 0, lastCycle);
            graphData.datasets = datasets;
          }

          groupedForecastsKeys.forEach((groupKey, index) => {
            forecastObj[groupKey] = aggregateQuarters(
              groupedForecasts[groupKey],
              year,
              groupKey,
            );

            // GET GRAPH DATA FOR current
            if (key === 'current') {
              // USE THE ABOVE TO ADD BORDERS FOR THE LAST GROUP IN CURRENT OBJECT
              if (index === groupedForecastsKeys.length - 1) {
                forecastObj[groupKey].groupedCells = true;
              }

              graphData.datasets = [
                ...(graphData.datasets || []),
                {
                  type: 'bar',
                  label: `Current ${groupKey}`,
                  data: sortByDateAfter(
                    groupedForecasts[groupKey],
                  ) as Record<string, number>[],
                  backgroundColor: graphColors[index],
                },
              ];
            }
          });

          // SUM LAST CYCLE AND CURRENT FORECAST
          const sumAggregators = sumArrayObjectsValues(
            Object.values(forecastObj),
          ) as TAny;

          if (Object.keys(sumAggregators).length) {
            sumAggregators.key = key;
            sumAggregators.field =
              key === 'current' ? 'Current FCST' : 'Last Cycle';

            forecastObj[key] = sumAggregators;

            // REORDER OBJECT
            const reorderedForecastObj = {
              [key]: null,
              ...forecastObj,
            };

            fiscalForecasts[`${key}-forecast`] = reorderedForecastObj;
          }
        });

        const forecastRows = [...Object.values(fiscalForecasts)]
          .map((value) => {
            if (value && typeof value === 'object') {
              return [...Object.values(value)];
            }
            return value;
          })
          .flat()
          .map((fiscal: TAny, position) => {
            if (fiscal && typeof fiscal === 'object') {
              fiscal.key = `${fiscal.key} - ${position}`;
            }
            return fiscal;
          });

        /* --- PROJECTION TABLE ---*/

        // GET PROJECTION FORECAST ACTUALS
        const projForecastRows = mapForecastProjection(forecast, [
          ...actualsMonths,
          moment(currentYear).format(DAILY_DISPLAY_FORMAT),
          ...forecastMonths,
        ]);

        const currentYearStart = moment(
          currentYear,
          'YYYY-MM',
        ).startOf('year');

        const yearToDateMonths = getDaysBetweenDates(
          currentYearStart,
          moment(currentYear, 'YYYY-MM-DD'),
        );

        const YTDActuals = yearToDateMonths.reduce(
          (prevValue, month) => prevValue + actual[month] || 0,
          0,
        );

        const currentYearToEndMonths = getDaysBetweenDates(
          moment(currentYear, 'YYYY-MM-DD'),
          moment(currentYear, 'YYYY-MM-DD').endOf('year'),
        );

        const currentForecastMonthTotal = {};
        Object.values(forecast.current).forEach((group: TAny) => {
          Object.keys(group).forEach((month) => {
            currentForecastMonthTotal[month] =
              (currentForecastMonthTotal[month] || 0) + group[month];
          });
        });

        const lastForecastMonthTotal = {};
        Object.values(forecast.last).forEach((group: TAny) => {
          Object.keys(group).forEach((month) => {
            lastForecastMonthTotal[month] =
              (lastForecastMonthTotal[month] || 0) + group[month];
          });
        });

        const FYRemaining = currentYearToEndMonths.reduce(
          (prevValue, month) => {
            return prevValue + currentForecastMonthTotal[month] || 0;
          },
          0,
        );

        const FCSTChange = currentYearToEndMonths.reduce(
          (prevValue, month) =>
            prevValue +
            (currentForecastMonthTotal[month] || 0) -
            (lastForecastMonthTotal[month] || 0),
          0,
        );

        const latestCurrentForecast = Object.keys(
          currentForecastMonthTotal,
        ).sort((a, b) =>
          moment(a, DAILY_DISPLAY_FORMAT).isAfter(
            moment(b, DAILY_DISPLAY_FORMAT),
          )
            ? -1
            : 1,
        )[0];

        const latestLastForecast = Object.keys(
          lastForecastMonthTotal,
        ).sort((a, b) =>
          moment(a, DAILY_DISPLAY_FORMAT).isAfter(
            moment(b, DAILY_DISPLAY_FORMAT),
          )
            ? -1
            : 1,
        )[0];

        const YOY =
          currentForecastMonthTotal[latestCurrentForecast] /
          lastForecastMonthTotal[latestLastForecast];

        const projectionFullYear = [
          {
            '% YOY': `${YOY || 0}%`,
          },
          '#YTD Act - YTD Bud',
          { 'YTD actuals': YTDActuals },
          {
            'FY Remaining': FYRemaining,
            'FCST Change': `(${FCSTChange})`,
          },
        ];

        let projectionTableData = [
          ...Object.values(projForecastRows).flat(),
        ].filter((projection: TAny) => projection?.field);

        projectionTableData = projectionTableData.map(
          (projection: TAny, index) => {
            projection.fiscalYear = projectionFullYear[index];

            if (index === projectionTableData.length - 1) {
              projection.lastColumn = true;
            }

            return projection;
          },
        );

        const fiscalAggregated = [
          actualRow,
          budgetRow,
          ...forecastRows,
        ].filter(
          (fiscalDatum) =>
            fiscalDatum && Object.keys(fiscalDatum).length,
        );

        const fiscalTableData = fiscalAggregated.map(
          (datum, index) => {
            if (index === fiscalAggregated.length - 1) {
              datum.lastColumn = true;
            }
            return datum;
          },
        );

        setAnalyticsData((datum) => ({
          ...datum,
          fiscalData: fiscalTableData,
          graphAnalytics: graphData,
          projectionData: projectionTableData as TAny,
          hasLoaded: true,
        }));
        return;
      }
      setAnalyticsData((datum) => ({
        ...datum,
        hasLoaded: true,
      }));
    },
    [currentYear, selectedNode, setAnalyticsData],
  );

  const onSelect = useCallback(
    async (keys, info) => {
      const lines = getFetchDataTreePayload(
        info.node,
        treeDataResponse,
      );
      await fetchAnalytics(lines);

      setSelectedNode((nodes) => ({
        ...nodes,
        analyticsKeys: info.node,
      }));
      setSelectedKeys((prevKeys) => ({
        ...prevKeys,
        analyticsKeys: keys,
      }));
    },
    [
      fetchAnalytics,
      setSelectedKeys,
      setSelectedNode,
      treeDataResponse,
    ],
  );

  useEffect(() => {
    if (selectedNode && Object.keys(selectedNode).length === 0) {
      setSelectedNode((nodes) => ({
        ...nodes,
        analyticsKeys: getSelectedNode(treeData),
      }));
    }
  }, [treeData, selectedNode, setSelectedNode]);

  return {
    fiscalData,
    hasLoaded,
    loading,
    nodeTitle: selectedNode.name,
    currentYear,
    actualsMonths,
    forecastMonths,
    graphAnalytics,
    projectionData,
    analyticsKeys,
    fetchAnalytics,
    setAnalyticsData,
    onSelect,
  };
};

export default useAnalytics;
