import React from 'react';
import { FiBell, FiSettings, FiUser, FiUsers } from "react-icons/fi";
import { useHistory } from 'react-router-dom';
import { Divider, Menu } from "antd";
import paths from 'app/routes/paths';

const SettingSideMenu = () => {
  const history = useHistory();
  const open = (path: string) => {
    history.push(path);
  };

  return (
    <div className="w-full h-full py-3 border-r flex-col justify-between">
      <span className='text-black text-lg font-medium px-1 pl-2 mt-1'>GENERAL SETTINGS</span>
      <Divider className='my-3'/>
      <Menu mode="inline" defaultOpenKeys={['settings']} className="w-full flex-1">
        <Menu.Item
          key="settings"
          icon={<FiSettings size="18px" />}
          className={`text-black font-medium p-3 hover:bg-primary ${
            history.location.pathname === paths.SETTINGS
              ? 'border-r-4 border-blue bg-primary text-blue' : ''
          }`}
          onClick={() => open(paths.SETTINGS)}
        >
          <div className="px-1">Settings</div>
        </Menu.Item>

        {/* <RestrictedTo to={[USER_ROLES.Admin.value]}> */}
          <Menu.Item
            key="setting-users"
            icon={<FiUsers size="18px" />}
            className={`text-black font-medium hover:text-blue p-3 ${
              history.location.pathname === paths.SETTINGS_USERS
                ? 'border-r-4 border-blue bg-primary text-blue' : ''
            }`}
            onClick={() => open(paths.SETTINGS_USERS)}
          >
            <div className="px-1">Users</div>
          </Menu.Item>
        {/* </RestrictedTo> */}

        <Menu.Item
          key="setting-profile"
          icon={<FiUser size="18px" />}
          className={`text-black font-medium hover:text-blue p-3 ${
            history.location.pathname === paths.SETTINGS_PROFILE
              ? 'border-r-4 border-blue bg-primary text-blue' : ''
          }`}
          onClick={() => open(paths.SETTINGS_PROFILE)}
        >
          <div className="px-1">Profile</div>
        </Menu.Item>

        <Menu.Item
          key="setting-notification"
          icon={<FiBell size="18px" />}
          className={`text-black font-medium hover:text-blue p-3 ${
            history.location.pathname === paths.SETTINGS_NOTIFICATION
              ? 'border-r-4 border-blue bg-primary text-blue' : ''
          }`}
          onClick={() => open(paths.SETTINGS_NOTIFICATION)}
        >
          <div className="px-1">Notification</div>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default SettingSideMenu;
