import moment from "moment";
import readXlsxFile from "read-excel-file";
import { TAny } from "app/typings";
import { DAILY_DATE_FORMAT } from "./constants";
import { ValidationError } from "./index";

const excelNumberToDate = (value: unknown) => {
  const isDate = value instanceof Date && !Number.isNaN(value);
  if (isDate) {
    return moment(value).format(DAILY_DATE_FORMAT);
  }
  if (typeof value === 'number') {
    return moment(new Date(Date.UTC(0, 0, value - 1))).format(
      DAILY_DATE_FORMAT,
    );
  }
  return value;
};

export const getPayload = async (file: File, name: string, type: string) => {
  try {
    const rows = await readXlsxFile(file);

    const startIndex = rows.findIndex((row: Array<TAny>) => {
      return row.some((value: TAny) => value === 'Region');
    });

    const isForecast = type === 'forecast';

    const dataColumnsStart = isForecast ? 6 : 5;
    const datesRow = rows[startIndex]
      .slice(dataColumnsStart)
      .map(excelNumberToDate);

    rows.splice(0, startIndex + 1);

    if (
      isForecast &&
      typeof rows[0]?.[1] === 'string' &&
      typeof rows[0]?.[2] === 'string' &&
      (rows[0]?.[1]?.replace(/\s+/g, '') === 'E.G.Cartridge' ||
        rows[0]?.[2]?.replace(/\s+/g, '') === 'E.G.USA')
    ) {
      rows.splice(0, 1);
    }

    if (
      !isForecast &&
      typeof rows[0]?.[0] === 'string' &&
      typeof rows[0]?.[1] === 'string' &&
      (rows[0]?.[0]?.replace(/\s+/g, '') === 'E.G.Cartridge' ||
        rows[0]?.[1]?.replace(/\s+/g, '') === 'E.G.USA')
    ) {
      rows.splice(0, 1);
    }

    return rows.map((row, index) => {
      const data = row
        .slice(dataColumnsStart)
        .map((value, index) => {
          return {
            date: datesRow[index],
            value: typeof value === 'number' ? Math.trunc(value) : 0,
          };
        })
        .filter((d) => d.date);
      if (isForecast) {
        const madeOn = excelNumberToDate(row[0]);
        if (madeOn === row[0]) {
          throw new ValidationError(
            `The 'Forecast month' is required and must be a date on row ${
              index + 1
            }`,
          );
        }
        return {
          made_on  : madeOn,
          line     : row[1] || 'N/A',
          region   : row[2] || 'N/A',
          group    : row[3] || 'N/A',
          item     : row[4] || 'N/A',
          reference: row[5] || '',
          data,
          file_name: name,
        };
      }

      return {
        line     : row[0] || 'N/A',
        region   : row[1] || 'N/A',
        group    : row[2] || 'N/A',
        item     : row[3] || 'N/A',
        reference: row[4] || '',
        data,
        file_name: name,
      };
    });
  } catch (error) {
    console.error(error)
    return Promise.reject(error);
  }
};
