import React from 'react';
import { Progress, Select } from 'antd';
import { useRecoilState } from 'recoil';
import { DemandPlanningState } from 'app/bloc/atoms';
import DropdownArrow from 'app/__portions/_drawables/DropdownArrow';
import {
  IModel,
  TPerformanceCheckers,
  TPredictionModel,
} from 'app/typings';
import SelectInput from 'app/__portions/SelectInput';
import {
  PERFORMANCE_CHOICES,
  USER_ROLES,
} from 'app/utils/helpers/constants';
import RestrictedTo from 'app/__portions/RestrictedTo';

const { Option } = Select;

interface IProps {
  onPredictionChange?: (predictionModel: TPredictionModel) => void;
}

const ModalCustomization = ({ onPredictionChange }: IProps) => {
  const [{ data, performance }, setState] = useRecoilState(
    DemandPlanningState,
  );

  const accuracy = data?.model?.accuracy;
  const isAccurate = accuracy && accuracy > 0;

  const onPerformanceChange = (performance: TPerformanceCheckers) => {
    const usersConsensus: Record<TPerformanceCheckers, IModel[]> = {
      Sales: data.consensus.salesConsensus,
      Planner: data.consensus.plannerConsensus,
      Machine: data.models as IModel[],
    };

    const currentPerformance = usersConsensus[performance]?.find(
      (consensus) => consensus?.name === data.model?.name,
    );

    setState((state) => ({
      ...state,
      performance,
      data: { ...state.data, model: currentPerformance },
    }));
  };

  return (
    <div className="w-full text-sm">
      <div className="font-bold">Prediction Details</div>
      <RestrictedTo to={[USER_ROLES.Sales.value]}>
        <div className="flex justify-between items-center border-b py-2">
          <div>Performances</div>
          <SelectInput
            className="rounded-sm select-options"
            defaultValue={performance}
            onChange={onPerformanceChange}
          >
            {Object.values(PERFORMANCE_CHOICES).map(
              ({ name, value }) => (
                <Option key={value} value={value}>
                  {name}
                </Option>
              ),
            )}
          </SelectInput>
        </div>
      </RestrictedTo>

      <div className="py-2 border-b ant-row ant-row-space-between ant-space-align-center">
        <div>Accuracy (wMAPE)</div>
        <Progress
          type={isAccurate ? 'dashboard' : 'circle'}
          format={(percent) => {
            if (!isAccurate) {
              return `${accuracy}`;
            }
            return `${percent}%`;
          }}
          percent={isAccurate ? accuracy : 100}
          width={80}
          strokeWidth={(isAccurate && 15) || undefined}
          status={isAccurate ? 'normal' : 'exception'}
        />
      </div>
      <div className="py-4 border-b ant-row ant-row-space-between ant-space-align-center">
        <div>Error Measure (RMSE)</div>
        <div className="text-blue font-bold">{data.model?.RMSE}</div>
      </div>
      <div className="py-4 border-b ant-row ant-row-space-between ant-space-align-center">
        <div>Best Prediction Method</div>

        <Select
          defaultValue={data.model?.name}
          bordered={false}
          className="prediction-select"
          suffixIcon={DropdownArrow}
          onChange={onPredictionChange}
          disabled={[
            PERFORMANCE_CHOICES.Sales.value,
            PERFORMANCE_CHOICES.Planner.value,
          ].includes(performance)}
        >
          {data.models?.map((model, index) => (
            <Option
              key={`${model.name}_${index.toFixed()}`}
              value={model?.name}
            >
              {model?.description}({model?.name})
            </Option>
          ))}
        </Select>
      </div>
    </div>
  );
};

ModalCustomization.defaultProps = {
  onPredictionChange: () => null,
};

export default ModalCustomization;
